import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import { EmpresaProcurador, findByIdAndMap } from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchProcurador, isEditableSelector, procuradoreSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

const { Tipo, Representacao } = EmpresaProcurador;

export default function EmpresaProcuradorDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const procurador = useSelector(procuradoreSelector);
  const { formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchProcurador.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const { moduloBasePath } = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/procuradores/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/procuradores`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/procuradores/${id}/`, `logs_/empresas/${empresaId}/procuradores/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Tipo')}>{findByIdAndMap(Tipo, procurador?.tipo, tipo => t(tipo.label))}</TextItem></Col>
        <Col flex="400px"><TextItem label={t('Nomes')}>{procurador?.nomes?.map(it => <div>{it}</div>)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Representação')}>{findByIdAndMap(Representacao, procurador?.representacao, representacao => t(representacao.label))}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Data Inicial')}>{formatDate(procurador?.dataInicial)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Prazo Indeterminado')}>{formatBoolean(procurador?.indeterminado)}</TextItem></Col>
        <Col flex="200px">
          {procurador?.indeterminado === false &&
            <TextItem label={t('Data Final')}>{formatDate(procurador?.dataFinal)}</TextItem>
          }
        </Col>
        <Col flex="200px"><TextItem label={t('Controle de Prazo')}>{formatBoolean(procurador?.aviso)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Poderes')}>{procurador?.poderes}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Anexo')} value={procurador?.anexo}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{procurador?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(procurador?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(procurador?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
      </Row>
    </Spin>
  );
}
