import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inicializado: false,
  acessoId: null,
  areaId: null,
  assuntoId: null,
  tipoTrabalhoId: null,
  usuarioId: null
};

export const consultivoFiltroSelector = state => state.consultivoFiltro;

export const slice = createSlice({
  name: 'consultivoFiltro',
  initialState,
  reducers: {
    consultivoFiltroResetar: (state) => {
      return { initialState, inicializado: false };
    },
    consultivoFiltroAplicar: (state, action) => {
      return { ...state, ...action.payload, inicializado: true, };
    }
  },
  extraReducers: {},
});

export default slice.reducer;

export const { consultivoFiltroResetar, consultivoFiltroAplicar } = slice.actions;
