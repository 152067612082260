import { Form, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import { fetchTributarios } from '../../../../store/empresa';
import unwrapAxiosPromise from '../../../../util/unwrapAxiosPromise';
import EmpresaTributarioForm from './_form';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaTributarioCreatePage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const { moduloBasePath } = useModulo();

  useEffect(() => {
    form.resetFields();
    if (history.location.state?.data)
      form.setFieldsValue(history.location.state.data);
    setImmediate(() => document.querySelector('#ano').focus());
  }, []);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.post(`/empresas/${empresaId}/tributarios`, values), form)
      .then(data => {
        message.success(t('Registro incluído com sucesso'));
        history.replace(`${moduloBasePath}/empresas/${empresaId}/tributarios`);
        dispatch(fetchTributarios({ empresaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/tributarios`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <EmpresaTributarioForm
      disabled={loadingSave}
      form={form}
      onFinish={handleFinish}
      title={title}
    />);
}
