import React, {useEffect} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import useDispatchWithResult from '../../../../hooks/useDispatchWithResult';
import {fetchTributario} from '../../../../store/empresa';
import EmpresaTributarioCreatePage from './_create';
import EmpresaTributarioDetailPage from './_detail';
import EmpresaTributarioEditPage from './_edit';

export default function EmpresaTributarioPage() {
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;

  useEffect(() => {
    dispatch(fetchTributario({empresaId, id}));
  }, [empresaId, id]);

  return (
      <Switch>
        <Route path="/*/empresas/:empresaId/tributarios/:id/edit" component={EmpresaTributarioEditPage} />
        <Route path="/*/empresas/:empresaId/tributarios/create" component={EmpresaTributarioCreatePage} />
        <Route path="/*/empresas/:empresaId/tributarios/:id" component={EmpresaTributarioDetailPage} />
      </Switch>);
}
