import {Col, Row, Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import TextItem from '../../../../../components/text-item';
import useFormatter from '../../../../../hooks/useFormatter';
import useLoading from '../../../../../hooks/useLoading';
import {fetchSocio, isEditableSelector, socioSelector} from '../../../../../store/empresa';
import {formatDocumento} from '../../../../../util/documento';
import {openPopup} from '../../../../../util/window';
import useModulo from "../../../../../hooks/useModulo";

export default function EmpresaSocioDetailPage8() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const socio = useSelector(socioSelector);
  const {formatBoolean, formatDateTime, formatCurrency, formatNumber} = useFormatter();
  const loading = useLoading(fetchSocio.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/socios/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/socios`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/socios/${id}/`, `logs_/empresas/${empresaId}/socios/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('CPF/CNPJ')}>{formatDocumento(socio?.documento)}</TextItem></Col>
          <Col flex="400px"><TextItem label={t('Nome')}>{socio?.nome}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Quotas')}>{formatNumber(socio?.capitalQuantidade)}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextItem label={t('Observação')}>{socio?.obs}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(socio?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(socio?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{socio?.id}</TextItem></Col>
        </Row>
      </Spin>
  );
}
