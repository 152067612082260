import { Form } from "antd";
import SelectConsultivoArea from "../../../components/select-consultivo-area";
import SelectConsultivoAssunto from "../../../components/select-consultivo-assunto";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import styles from './_ConsultivoFiltroForm.less';
import { FilterOutlined } from "@ant-design/icons";
import SelectConsultivoTipoTrabalho from "../../../components/select-consultivo-tipo-trabalho";
import SelectConsultivoUsuario from "../../../components/select-consultivo-usuario";
import { consultivoFiltroAplicar, consultivoFiltroSelector } from "../../../store/consultivoFiltro";
import { useSelector } from "react-redux";
import SelectAcesso from "../../../components/select-acesso";
import { acessoSelector } from "../../../store/acessos";
import { nivelAcessoSelector, usuarioIdSelector } from "../../../store/autenticacao";
import { Usuario } from "../../../constants";

export default function ConsultivoFiltroForm() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const filtro = useSelector(consultivoFiltroSelector);
  const acesso = useSelector(acessoSelector);
  const acessoId = acesso?.id;
  const usuarioId = useSelector(usuarioIdSelector);
  const nivelAcesso = useSelector(nivelAcessoSelector);

  useEffect(() => {
    if (!filtro.inicializado) {
      if (nivelAcesso <= Usuario.NivelAcesso.Gestor.id)
        dispatch(consultivoFiltroAplicar({ acessoId, usuarioId }));
      else
        dispatch(consultivoFiltroAplicar({ acessoId }));
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(filtro);
  }, [filtro])

  const handleValuesChange = useCallback(values => dispatch(consultivoFiltroAplicar(values)), []);

  return (
    <Form form={form} noValidate initialValues={filtro} onValuesChange={handleValuesChange}>
      <div className={styles.ConsultivoFiltroForm}>
        <div className={styles.acessoId}>
          <div className={styles.icone}>
            <div><FilterOutlined/></div>
            <div>{t('Acesso')}</div>
          </div>
          <Form.Item name="acessoId" noStyle>
            <SelectAcesso allowClear/>
          </Form.Item>
        </div>
        <div className={styles.areaId}>
          <div className={styles.icone}>
            <div><FilterOutlined/></div>
            <div>{t('Área de Atuação')}</div>
          </div>
          <Form.Item name="areaId" noStyle>
            <SelectConsultivoArea allowClear/>
          </Form.Item>
        </div>
        <div className={styles.tipoTrabalhoId}>
          <div className={styles.icone}>
            <div><FilterOutlined/></div>
            <div>{t('Tipo de Trabalho')}</div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="tipoTrabalhoId" noStyle>
                <SelectConsultivoTipoTrabalho areaId={getFieldValue('areaId')} allowClear/>
              </Form.Item>
            }
          </Form.Item>
        </div>
        <div className={styles.assuntoId}>
          <div className={styles.icone}>
            <div><FilterOutlined/></div>
            <div>{t('Assunto')}</div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="assuntoId" noStyle>
                <SelectConsultivoAssunto areaId={getFieldValue('areaId')} allowClear/>
              </Form.Item>
            }
          </Form.Item>
        </div>
        <div className={styles.usuarioId}>
          <div className={styles.icone}>
            <div><FilterOutlined/></div>
            <div>{t('Usuário')}</div>
          </div>
          <Form.Item name="usuarioId" noStyle>
            <SelectConsultivoUsuario allowClear/>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}
