import ActionButton from "../action-button";
import React, { useState } from "react";
import axios from "axios";
import { substringAfter } from "../../util/string";
import { useTranslation } from "react-i18next";
import { Checkbox, Popover, Radio } from "antd";
import styles from './_styles.less';
import ActionBar from "../action-bar";

export default function ActionButtonEmpresaLivroDigitalGerarPdf({ disabled, empresaId, livroDigital, ...props }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [parcial, setParcial] = useState(false);
  const [secoes, setSecoes] = useState([]);

  const handleParcialChange = e => {
    setParcial(e.target.value);
  };

  const handleGerarPdf = async () => {
    setLoading(true);
    try {
      let termoAbertura = false;
      let declaracaoResponsabilidade = false;
      let termoEncerramento = false;
      let termoAutenticacao = false;
      const conteudos = [];
      for (const secao of secoes) {
        switch (secao) {
          case 'termoAbertura':
            termoAbertura = true;
            break;
          case 'declaracaoResponsabilidade':
            declaracaoResponsabilidade = true;
            break;
          case 'termoEncerramento':
            termoEncerramento = true;
            break;
          case 'termoAutenticacao':
            termoAutenticacao = true;
            break;
          default:
            conteudos.push(Number(substringAfter(secao, 'conteudo-')));
            break;
        }
      }
      const res = (await axios.post(`/empresas/${empresaId}/livros-digitais/${livroDigital?.id}/gerar-pdf`, { parcial, termoAbertura, conteudos, declaracaoResponsabilidade, termoEncerramento, termoAutenticacao }, { responseType: 'arraybuffer' }));
      const filename = substringAfter(res.headers['content-disposition'], 'filename=') || 'Livro Digital.pdf';
      const type = res.headers['content-type'] || 'application/octet-stream';
      const blob = new Blob([res.data], { type });
      const a = document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('href', window.URL.createObjectURL(blob));
      a.setAttribute('download', filename);
      a.click();
      setOpen(false);
    } finally {
      setLoading(false);
    }
  }

  const options = [];
  if (livroDigital) {
    if (livroDigital.anexoTermoAbertura)
      options.push({ label: t('Termo de Abertura'), value: 'termoAbertura' });
    livroDigital.conteudos?.forEach(conteudo => options.push({ label: conteudo.nome, value: 'conteudo-' + conteudo.id }));
    if (livroDigital.anexoDeclaracaoResponsabilidade)
      options.push({ label: t('Declaração de Responsabilidade'), value: 'declaracaoResponsabilidade' });
    if (livroDigital.anexoTermoEncerramento)
      options.push({ label: t('Termo de Encerramento'), value: 'termoEncerramento' });
    if (livroDigital.anexoTermoAutenticacao)
      options.push({ label: t('Termo de Autenticação'), value: 'termoAutenticacao' });
  }

  const content = (
    <div className={styles['popover']}>
      <label>
        {t('Gerar PDF')}
        <Radio.Group
          className={styles['checkbox-vertical']}
          defaultValue={false}
          disabled={loading}
          options={[
            { label: t('Documento Completo'), value: false },
            { label: t('Documento Parcial'), value: true },
          ]}
          value={parcial}
          onChange={handleParcialChange}
        />
      </label>
      {parcial &&
        <label>
          {t('Seções')}
          <Checkbox.Group
            className={styles['checkbox-vertical']}
            defaultValue={[]}
            disabled={loading}
            options={options}
            value={secoes}
            onChange={setSecoes}
          />
        </label>
      }
      <ActionBar>
        <ActionButton.GeneratePdf
          loading={loading}
          primary
          onClick={handleGerarPdf}
        />
        <ActionButton.Cancel
          disabled={loading}
          onClick={() => setOpen(false)}
        />
      </ActionBar>
    </div>
  );

  return (
    <Popover
      content={content}
      disabled={disabled || !livroDigital}
      trigger="click"
      onOpenChange={setOpen}
      open={open}
    >
      <ActionButton.GeneratePdf
        disabled={disabled || !livroDigital}
        loading={loading}
        {...props}
      />
    </Popover>
  )
}
