import React, { useEffect } from "react";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { consultivoFiltroResetar } from "../../store/consultivoFiltro";
import { Redirect } from "react-router";

export default function ConsultivoPage() {
  const dispatch = useDispatchWithResult();

  useEffect(() => {
    dispatch(consultivoFiltroResetar());
  }, [])

  return <Redirect to="/consultivo/dashboard"/>;
}
