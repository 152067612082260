import { Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from 'react';
import { cadastroSelector, fetchCadastro } from "../../../../store/consultivoArea";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import useLoading from "../../../../hooks/useLoading";
import { useHistory, useRouteMatch } from "react-router";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import MiniForm from "../../../../components/mini-form";
import InputName from "../../../../components/input-name";
import InputColor from "../../../../components/input-color";
import SelectConsultivoArea from "../../../../components/select-consultivo-area";
import SelectConsultivoUsuario from "../../../../components/select-consultivo-usuario";

export default function ConsultivoAreaCadastroEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;

  useEffect(() => {
    document.querySelector('#nome').focus();
  }, []);

  useEffect(() => {
    form.setFieldsValue(cadastro);
  }, [cadastro]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/areas/${areaId}/cadastro`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`/consultivo/areas/${areaId}/cadastro`);
        dispatch(fetchCadastro({ areaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/areas/${areaId}/cadastro`);
  };

  return (
    <MiniForm form={form} onFinish={handleFinish} loading={loading}>
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="nome" label={t('Nome')}>
            <Input disabled={loadingSave} maxLength={100}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="cor" label={t('Cor')}>
            <InputColor disabled={loadingSave}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="areaPaiId" label={t('Área de Atuação Pai')}>
            <SelectConsultivoArea disabled={loadingSave} allowClear filter={area => cadastro?.id !== area.id} filterChildren/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="responsavelId" label={t('Responsável')}>
            <SelectConsultivoUsuario disabled={loadingSave} allowClear/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="idExterno" label={t('Id Externo')}>
            <Input disabled={loadingSave} maxLength={100}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>
  )
}
