import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaTributario } from '../../constants';

const { RegimeApuracao } = EmpresaTributario;

export default function SelectEmpresaTributarioRegimeApuracao(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(RegimeApuracao.Normal)}
      {createOption(RegimeApuracao.SimplesNacional)}
      {createOption(RegimeApuracao.NA)}
    </Select>
  );
}

SelectEmpresaTributarioRegimeApuracao.propTypes = {
  value: PropTypes.oneOf([
    RegimeApuracao.Normal.id,
    RegimeApuracao.SimplesNacional.id,
    RegimeApuracao.NA.id,
  ]),
  onChange: PropTypes.func,
};
