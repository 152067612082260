import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputRazaoSocial from '../../../../../components/input-razao-social';
import MiniForm from '../../../../../components/mini-form';
import InputColor from "../../../../../components/input-color";
import InputConsultivoAreaAssuntoFases from "../../../../../components/input-consultivo-area-assunto-fases";

export default function ConsultivoAreaAssuntoForm({ disabled, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="descricao" label={t('Descrição')}>
            <Input disabled={disabled} maxLength={100}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="cor" label={t('Cor')}>
            <InputColor disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="fases" label={t('Fases')}>
            <InputConsultivoAreaAssuntoFases name="fases" disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

ConsultivoAreaAssuntoForm.propTypes = {
  areaId: PropTypes.number,
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
