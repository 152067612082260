import {Col, Row, Spin} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextItem from '../../../../components/text-item';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import {fetchPL, isEditableSelector, plSelector} from '../../../../store/empresa';
import {openPopup} from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaPLDetailPage() {
  const {t} = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const pl = useSelector(plSelector);
  const {formatDateTime, formatDate, formatCurrency, formatBoolean} = useFormatter();
  const loading = useLoading(fetchPL.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const {moduloBasePath} = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/pls/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/pls`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/pls/${id}/`, `logs_/empresas/${empresaId}/pls/${id}/`);
  };

  return (
      <Spin spinning={loading}>
        <ActionBar>
          {isEditable && <ActionButton.Edit onClick={handleEdit} />}
          <ActionButton.Back onClick={handleBack} />
          <ActionButton.Logs noTitle onClick={handleLogs} />
        </ActionBar>
        <Row gutter={[16, 8]}>
          <Col flex="150px"><TextItem label={t('Data Base')}>{formatDate(pl?.dataReferencia)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Patrimônio Líquido (R$)')}>{formatCurrency(pl?.valorReal)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Patrimônio Líquido (US$)')}>{formatCurrency(pl?.valorDolar, {currency: 'USD'})}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Ativos Totais (R$)')}>{formatCurrency(pl?.ativoReal, {currency: 'USD'})}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="350px">
            <TextItem
                label={t('Saldo Devedor Créditos Estrangeiros (US$)')}
                tooltip={t('Indicar saldo devedor total de créditos comerciais de curto prazo ' +
                    '(exigíveis em 30 até 360 dias) concedidos por não residentes. Operações de prazo entre zero e 29 ' +
                    'dias são consideradas à vista, e estão dispensadas de declaração.')}
            >
              {formatCurrency(pl?.saldoDevedor)}
            </TextItem>
          </Col>
          <Col flex="200px">
            <TextItem
                label={t('Ativos no Exterior')}
                tooltip={t('São valores de qualquer natureza mantidos fora do país por ' +
                    'residentes no Brasil. Podem ser bens, direitos, instrumentos financeiros, disponibilidades em ' +
                    'moedas estrangeiras, depósitos, imóveis, participações em empresas, ações, títulos, créditos ' +
                    'comerciais e etc.')}
            >
              {formatBoolean(pl?.ativosExterior)}
            </TextItem>
          </Col>
          <Col flex="200px">
            {pl?.ativosExterior === true &&
            <TextItem label={t('Valor de Ativos no Exterior (US$)')}>{formatCurrency(pl?.valorAtivosExterior)}</TextItem>
            }
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="150px"><TextItem label={t('Data da Declaração')}>{formatDate(pl?.dataDeclaracao)}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(pl?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(pl?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
        </Row>
      </Spin>
  );
}
