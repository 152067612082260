import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import { compareStrings } from '../../../util/string';
import { compareNumbers } from "../../../util/number";
import { EmpresaTributario, findByIdAndMap } from "../../../constants";

const { RegimeApuracao, FormaTributacaoLucro, IncidenciaTributaria, CriterioApuracao, } = EmpresaTributario;

export default function EmpresaTributariosTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Ano'),
      dataIndex: 'ano',
      key: 'ano',
      sorter: (a, b) => compareStrings(a.ano, b.ano),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Regime de Apuração'),
      dataIndex: 'regimeApuracao',
      key: 'regimeApuracao',
      sorter: (a, b) => compareNumbers(a.regimeApuracao, b.regimeApuracao),
      render: (text, record) => findByIdAndMap(RegimeApuracao, record.regimeApuracao, it => t(it.label)),
    },
    {
      title: t('Forma de Tributação do Lucro'),
      dataIndex: 'formaTributacaoLucro',
      key: 'formaTributacaoLucro',
      sorter: (a, b) => compareNumbers(a.formaTributacaoLucro, b.formaTributacaoLucro),
      render: (text, record) => findByIdAndMap(FormaTributacaoLucro, record.formaTributacaoLucro, it => t(it.label)),
    },
    {
      title: t('Incidência Tributária'),
      dataIndex: 'incidenciaTributaria',
      key: 'incidenciaTributaria',
      sorter: (a, b) => compareNumbers(a.incidenciaTributaria, b.incidenciaTributaria),
      render: (text, record) => findByIdAndMap(IncidenciaTributaria, record.incidenciaTributaria, it => t(it.label)),
    },
    {
      title: t('Critério de Apuração'),
      dataIndex: 'criterioApuracao',
      key: 'criterioApuracao',
      sorter: (a, b) => compareNumbers(a.criterioApuracao, b.criterioApuracao),
      render: (text, record) => findByIdAndMap(CriterioApuracao, record.criterioApuracao, it => t(it.label)),
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

EmpresaTributariosTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
