import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import { formatCpf } from '../../../../util/cpf';
import { compareStrings } from "../../../../util/string";
import TableCellEllipsis from "../../../../components/table-cell-ellipsis";
import { compareNumbers } from "../../../../util/number";
import useFormatter from "../../../../hooks/useFormatter";

export default function EmpresaSociosTable4({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();
  const { formatCurrency } = useFormatter();

  const fireClick = socio => typeof onClick === 'function' ? onClick(socio) : undefined;

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('CPF'),
      dataIndex: 'documento',
      key: 'documento',
      render: (text, record) => formatCpf(text),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

EmpresaSociosTable4.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
