import { CheckCircleFilled, MinusCircleFilled, StopFilled, WarningFilled } from '@ant-design/icons';
import { Button } from 'antd';
import axios from 'axios';
import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActionButton from '../../../components/action-button';
import MiniTable from '../../../components/mini-table';
import { Alerta, EmpresaTabs } from '../../../constants';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import { acessoSelector } from '../../../store/acessos';
import { funcoesSelector, isClienteGestorSelector } from '../../../store/autenticacao';
import { fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas, filtradoSelector, filtroSelector } from '../../../store/empresas';
import { empresasSelector } from '../../../store/societarioTabela';
import { compareStrings } from '../../../util/string';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import { openPopupEmpresa } from '../../../util/window';
import styles from './_styles.less';

function getAlertIcon(nivel) {
  switch (nivel) {
    default:
      return <CheckCircleFilled className={styles['alert-icon'] + ' primary'}/>;
    case Alerta.Nivel.Aviso.id:
      return <WarningFilled className={styles['alert-icon'] + ' warning'}/>;
    case Alerta.Nivel.Erro.id:
      return <MinusCircleFilled className={styles['alert-icon'] + ' error'}/>;
    case Alerta.Nivel.NA.id:
      return <StopFilled className={styles['alert-icon'] + ' inactive'}/>;
  }
}

function EmpresaColumn({ name, alertas, disabled, loading, onClick }) {
  const { t } = useTranslation();
  const isNA = alertas?.nivel === Alerta.Nivel.NA.id;
  const className = [styles['button']];
  if (isNA)
    className.push('inactive');
  const icon = loading ? null : getAlertIcon(alertas?.nivel);

  return (
    <Button
      block
      className={className.join(' ')}
      disabled={disabled}
      type="text"
      onClick={e => {
        e.preventDefault();
        onClick(name);
      }}
    >
      <span className={styles['button-icon']}>{icon}</span>
    </Button>
  );
}

export default function EmpresasTable() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const funcoes = useSelector(funcoesSelector);
  const acesso = useSelector(acessoSelector);
  const empresas = useSelector(empresasSelector);
  const filtro = useSelector(filtroSelector);
  const filtrado = useSelector(filtradoSelector);
  const [loadingRemove, setLoadingRemove] = useState();
  const [apelidoWidth, setApelidoWidth] = useState();

  useLayoutEffect(() => {
    function resize() {
      setApelidoWidth(Math.min(Math.max(window.innerWidth - 1000, 160), 240));
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const onUnload = () => {
    dispatch(fetchEmpresas({ acessoId: acesso?.id }));
  };

  const fireClick = (empresa, tab) => {
    openPopupEmpresa(empresa, tab, { onUnload });
  };

  const handleRemoveClick = empresa => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete('/empresas', { params: { ids: [empresa.id] } }))
      .then(() => dispatch(fetchEmpresas(acesso)))
      .finally(() => setLoadingRemove(false));
  };

  const columns = [
    {
      title: t('Empresa'),
      dataIndex: 'apelido',
      key: 'apelido',
      width: apelidoWidth,
      fixed: 'left',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => (
        <Button
          block
          className={styles['button-empresa']}
          type="text"
          onClick={() => fireClick(record, 'cadastro')}
        >
          <div className={styles['empresa']}>
            {record.apelido || record.razaoSocial}
          </div>
        </Button>
      ),
    },
  ];

  let tabsFiltered = EmpresaTabs
    .filter(tab => tab.modulos.includes('societario') === true)
    .filter(tab => funcoes?.includes(tab.name) && acesso?.funcoes?.includes(tab.name));
  const tabs = new Set();
  for (const empresa of empresas) {
    const { abas } = empresa;
    for (const tab of tabsFiltered) {
      const aba = abas[tab.name];
      if (!aba)
        continue;
      if (aba.nivel === Alerta.Nivel.NA.id)
        continue;
      if (filtrado) {
        if (filtro.tabs.length > 0) {
          if (!filtro.tabs.includes(aba.nome)) {
            continue;
          }
        }
        if (filtro.sucessos || filtro.avisos || filtro.erros) {
          switch (aba.nivel) {
            default:
              if (!filtro.sucessos)
                continue;
              break;
            case Alerta.Nivel.NA.id:
              continue;
            case Alerta.Nivel.Aviso.id:
              if (!filtro.avisos)
                continue;
              break;
            case Alerta.Nivel.Erro.id:
              if (!filtro.erros)
                continue;
              break;
          }
        }
      }
      tabs.add(tab);
    }
  }
  tabsFiltered = [...tabs];

  for (const tab of tabsFiltered) {
    columns.push({
      title: t(tab.shortLabel),
      key: tab.name,
      align: 'center',
      width: 82,
      render: (text, record) => (
        <EmpresaColumn
          empresa={record}
          alertas={record.abas[tab.name]}
          disabled={loading}
          loading={loading}
          onClick={name => fireClick(record, name)}
          {...tab}
        />),
    });
  }

  columns.push({
    key: 'actions',
    width: 60,
    render: (text, record) => {
      const buttons = [];
      if (isClienteGestor)
        buttons.push(
          <ActionButton.Remove
            key="remove"
            disabled={loadingRemove}
            noTitle
            onClick={() => handleRemoveClick(record)}
          />);
      return <div className={styles['actions']}>{buttons}</div>;
    },
  });

  return (
    <MiniTable
      className={styles['table']}
      columns={columns}
      dataSource={empresas}
      loading={loading}
      rowClassName={record => record.ativa === true ? undefined : 'inactive'}
      pagination={false}
      scroll={{ x: 1390, y: 'auto' }}
    />);
}
