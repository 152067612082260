import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { empresaIdsSelector, isClienteOperadorSelector, isOperadorSelector } from './autenticacao';

const initialState = {
  // CABECALHO

  cabecalho: {},

  // CADASTRO

  cadastro: {},
  cadastroAlertaGrupo: {},

  // UNIDADES

  unidades: [],
  unidadesAlertaGrupo: {},
  unidade: {},

  // CERTIFICADOS

  certificados: [],
  certificadosAlertaGrupo: {},
  certificado: {},

  // TRIBUTARIOS

  tributarios: [],
  tributariosAlertaGrupo: {},
  tributario: {},

  // SÓCIOS

  socios: [],
  sociosAlertaGrupo: {},
  socio: {},

  // CONTRATOS

  contratos: [],
  contratosAlertaGrupo: {},
  contrato: {},

  // PLS

  pls: [],
  plsAlertaGrupo: {},
  pl: {},

  // ADMINISTRADORES

  administradores: [],
  administradoresAlertaGrupo: {},
  administrador: {},

  // PROCURADORES

  procuradores: [],
  procuradoresAlertaGrupo: {},
  procurador: {},

  // CONTAS

  contas: [],
  contasAlertaGrupo: {},
  conta: {},

  // BENEFICIÁRIOS EMPRESA

  beneficiariosEmpresa: [],
  beneficiariosEmpresaAlertaGrupo: {},
  beneficiarioEmpresa: {},

  // REPRESENTANTES

  representantes: [],
  representantesAlertaGrupo: {},
  representante: {},

  // BENEFICIÁRIOS SÓCIO

  beneficiariosSocio: [],
  beneficiariosSocioAlertaGrupo: {},
  beneficiarioSocio: {},

  // IEDS

  rdeieds: [],
  rdeiedsAlertaGrupo: {},
  rdeied: {},

  // DEFs

  defs: [],
  defsAlertaGrupo: {},
  def: {},

  // CCEs

  cces: [],
  ccesAlertaGrupo: {},
  cce: {},

  // CBEs

  cbes: [],
  cbesAlertaGrupo: {},
  cbe: {},

  // LICENÇAS

  licencas: [],
  licencasSearch: '',
  licencasAlertaGrupo: {},
  licenca: {},

  // DOCUMENTOS

  documentos: [],
  documentosSearch: '',
  documentosAlertaGrupo: {},
  documento: {},

  // LIVROS DIGITAIS

  livrosDigitais: [],
  livrosDigitaisAlertaGrupo: {},
  livroDigital: {},
};

// CABECALHO

export const cabecalhoSelector = state => state.empresa.cabecalho;

// CADASTRO

export const cadastroSelector = state => state.empresa.cadastro;
export const cadastroAlertaGrupoSelector = state => state.empresa.cadastroAlertaGrupo;

export const cadastroAlertaNivelSelector = createSelector(cadastroAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const cadastroAlertasSelector = createSelector(cadastroAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// UNIDADES

export const unidadesSelector = state => state.empresa.unidades;
export const unidadesAlertaGrupoSelector = state => state.empresa.unidadesAlertaGrupo;
export const unidadeSelector = state => state.empresa.unidade;

export const unidadesAlertaNivelSelector = createSelector(unidadesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const unidadesAlertasSelector = createSelector(unidadesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// CERTIFICADOS

export const certificadosSelector = state => state.empresa.certificados;
export const certificadosAlertaGrupoSelector = state => state.empresa.certificadosAlertaGrupo;
export const certificadoSelector = state => state.empresa.certificado;

export const certificadosAlertaNivelSelector = createSelector(certificadosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const certificadosAlertasSelector = createSelector(certificadosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// TRIBUTARIOS

export const tributariosSelector = state => state.empresa.tributarios;
export const tributariosAlertaGrupoSelector = state => state.empresa.tributariosAlertaGrupo;
export const tributarioSelector = state => state.empresa.tributario;

export const tributariosAlertaNivelSelector = createSelector(tributariosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const tributariosAlertasSelector = createSelector(tributariosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// SÓCIOS

export const sociosSelector = state => state.empresa.socios;
export const sociosAlertaGrupoSelector = state => state.empresa.sociosAlertaGrupo;
export const socioSelector = state => state.empresa.socio;

export const sociosAlertaNivelSelector = createSelector(sociosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const sociosAlertasSelector = createSelector(sociosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// CONTRATOS

export const contratosSelector = state => state.empresa.contratos;
export const contratosAlertaGrupoSelector = state => state.empresa.contratosAlertaGrupo;
export const contratoSelector = state => state.empresa.contrato;

export const contratosAlertaNivelSelector = createSelector(contratosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const contratosAlertasSelector = createSelector(contratosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// PLS

export const plsSelector = state => state.empresa.pls;
export const plsAlertaGrupoSelector = state => state.empresa.plsAlertaGrupo;
export const plSelector = state => state.empresa.pl;

export const plsAlertaNivelSelector = createSelector(plsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const plsAlertasSelector = createSelector(plsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// ADMINISTRADORES

export const administradoresSelector = state => state.empresa.administradores;
export const administradoresAlertaGrupoSelector = state => state.empresa.administradoresAlertaGrupo;
export const administradoreSelector = state => state.empresa.administrador;

export const administradoresAlertaNivelSelector = createSelector(administradoresAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const administradoresAlertasSelector = createSelector(administradoresAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// PROCURADORES

export const procuradoresSelector = state => state.empresa.procuradores;
export const procuradoresAlertaGrupoSelector = state => state.empresa.procuradoresAlertaGrupo;
export const procuradoreSelector = state => state.empresa.procurador;

export const procuradoresAlertaNivelSelector = createSelector(procuradoresAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const procuradoresAlertasSelector = createSelector(procuradoresAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// CONTAS

export const contasSelector = state => state.empresa.contas;
export const contasAlertaGrupoSelector = state => state.empresa.contasAlertaGrupo;
export const contaSelector = state => state.empresa.conta;

export const contasAlertaNivelSelector = createSelector(contasAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const contasAlertasSelector = createSelector(contasAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// BENEFICIÁRIOS EMPRESA

export const beneficiariosEmpresaSelector = state => state.empresa.beneficiariosEmpresa;
export const beneficiariosEmpresaAlertaGrupoSelector = state => state.empresa.beneficiariosEmpresaAlertaGrupo;
export const beneficiariosEmpresaelector = state => state.empresa.beneficiarioEmpresa;

export const beneficiariosEmpresaAlertaNivelSelector = createSelector(beneficiariosEmpresaAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const beneficiariosEmpresaAlertasSelector = createSelector(beneficiariosEmpresaAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// REPRESENTANTES

export const representantesSelector = state => state.empresa.representantes;
export const representantesAlertaGrupoSelector = state => state.empresa.representantesAlertaGrupo;
export const representanteSelector = state => state.empresa.representante;

export const representantesAlertaNivelSelector = createSelector(representantesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const representantesAlertasSelector = createSelector(representantesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// BENEFICIÁRIOS SÓCIO

export const beneficiariosSocioSelector = state => state.empresa.beneficiariosSocio;
export const beneficiariosSocioAlertaGrupoSelector = state => state.empresa.beneficiariosSocioAlertaGrupo;
export const beneficiariosSocioelector = state => state.empresa.beneficiarioSocio;

export const beneficiariosSocioAlertaNivelSelector = createSelector(beneficiariosSocioAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const beneficiariosSocioAlertasSelector = createSelector(beneficiariosSocioAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// RDEIEDS

export const rdeiedsSelector = state => state.empresa.rdeieds;
export const rdeiedsAlertaGrupoSelector = state => state.empresa.rdeiedsAlertaGrupo;
export const rdeiedSelector = state => state.empresa.rdeied;

export const rdeiedsAlertaNivelSelector = createSelector(rdeiedsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const rdeiedsAlertasSelector = createSelector(rdeiedsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// DEFs

export const defsSelector = state => state.empresa.defs;
export const defsAlertaGrupoSelector = state => state.empresa.defsAlertaGrupo;
export const defSelector = state => state.empresa.def;

export const defsAlertaNivelSelector = createSelector(defsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const defsAlertasSelector = createSelector(defsAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// CCEs

export const ccesSelector = state => state.empresa.cces;
export const ccesAlertaGrupoSelector = state => state.empresa.ccesAlertaGrupo;
export const cceSelector = state => state.empresa.cce;

export const ccesAlertaNivelSelector = createSelector(ccesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const ccesAlertasSelector = createSelector(ccesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// CBEs

export const cbesSelector = state => state.empresa.cbes;
export const cbesAlertaGrupoSelector = state => state.empresa.cbesAlertaGrupo;
export const cbeSelector = state => state.empresa.cbe;

export const cbesAlertaNivelSelector = createSelector(cbesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const cbesAlertasSelector = createSelector(cbesAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// LICENÇAS

export const licencasSelector = state => state.empresa.licencas;
export const licencasSearchSelector = state => state.empresa.licencasSearch;
export const licencasAlertaGrupoSelector = state => state.empresa.licencasAlertaGrupo;
export const licencaSelector = state => state.empresa.licenca;

export const licencasAlertaNivelSelector = createSelector(licencasAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const licencasAlertasSelector = createSelector(licencasAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// DOCUMENTOS

export const documentosSelector = state => state.empresa.documentos;
export const documentosSearchSelector = state => state.empresa.documentosSearch;
export const documentosAlertaGrupoSelector = state => state.empresa.documentosAlertaGrupo;
export const documentoSelector = state => state.empresa.documento;

export const documentosAlertaNivelSelector = createSelector(documentosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const documentosAlertasSelector = createSelector(documentosAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// LIVROS DIGITAIS

export const livrosDigitaisSelector = state => state.empresa.livrosDigitais;
export const livrosDigitaisAlertaGrupoSelector = state => state.empresa.livrosDigitaisAlertaGrupo;
export const livroDigitalSelector = state => state.empresa.livroDigital;

export const livrosDigitaisAlertaNivelSelector = createSelector(livrosDigitaisAlertaGrupoSelector, alertaGrupo => alertaGrupo?.nivel ?? 0);
export const livrosDigitaisAlertasSelector = createSelector(livrosDigitaisAlertaGrupoSelector, alertaGrupo => alertaGrupo?.alertas ?? []);

// ALERTA

export const alertaGrupoSelector = createSelector(cadastroAlertaGrupoSelector, unidadesAlertaGrupoSelector, certificadosAlertaGrupoSelector, tributariosAlertaGrupoSelector, sociosAlertaGrupoSelector, contratosAlertaGrupoSelector, plsAlertaGrupoSelector, administradoresAlertaGrupoSelector, procuradoresAlertaGrupoSelector, contasAlertaGrupoSelector, beneficiariosEmpresaAlertaGrupoSelector, representantesAlertaGrupoSelector, beneficiariosSocioAlertaGrupoSelector, rdeiedsAlertaGrupoSelector, defsAlertaGrupoSelector, ccesAlertaGrupoSelector, cbesAlertaGrupoSelector, licencasAlertaGrupoSelector, documentosAlertaGrupoSelector, livrosDigitaisAlertaGrupoSelector,
  (cadastroAlertaGrupo, unidadesAlertaGrupo, certificadosAlertaGrupo, tributariosAlertaGrupo, sociosAlertaGrupo, contratosAlertaGrupo, plsAlertaGrupo, administradoresAlertaGrupo, procuradoresAlertaGrupo, contasAlertaGrupo, beneficiariosEmpresaAlertaGrupo, representantesAlertaGrupo, beneficiariosSocioAlertaGrupo, rdeiedsAlertaGrupo, defsAlertaGrupo, ccesAlertaGrupo, cbesAlertaGrupo, licencasAlertaGrupo, documentosAlertaGrupo, livrosDigitaisAlertaGrupo) => ({
    cadastro: cadastroAlertaGrupo ?? {},
    unidades: unidadesAlertaGrupo ?? {},
    certificados: certificadosAlertaGrupo ?? {},
    tributarios: tributariosAlertaGrupo ?? {},
    socios: sociosAlertaGrupo ?? {},
    contratos: contratosAlertaGrupo ?? {},
    pls: plsAlertaGrupo ?? {},
    administradores: administradoresAlertaGrupo ?? {},
    procuradores: procuradoresAlertaGrupo ?? {},
    contas: contasAlertaGrupo ?? {},
    beneficiariosEmpresa: beneficiariosEmpresaAlertaGrupo ?? {},
    representantes: representantesAlertaGrupo ?? {},
    beneficiariosSocio: beneficiariosSocioAlertaGrupo ?? {},
    rdeieds: rdeiedsAlertaGrupo ?? {},
    defs: defsAlertaGrupo ?? {},
    cces: ccesAlertaGrupo ?? {},
    cbes: cbesAlertaGrupo ?? {},
    licencas: licencasAlertaGrupo ?? {},
    documentos: documentosAlertaGrupo ?? {},
    livrosDigitais: livrosDigitaisAlertaGrupo ?? {},
  }));

// CABECALHO

export const isEditableSelector = createSelector(cabecalhoSelector, empresaIdsSelector, isClienteOperadorSelector, isOperadorSelector,
  (cabecalho, empresaIds, isClienteOperador, isOperador) => {
    console.log(cabecalho, empresaIds.includes(cabecalho?.id))
    if (isOperador)
      return true;
    if (isClienteOperador && empresaIds.includes(cabecalho?.id))
      return true;
    return false;
  });

export const fetchCabecalho = createAsyncThunk(
  'empresa/fetchCabecalho',
  async ({ empresaId }, thunkAPI) => {
    try {
      const cabecalho = (await axios.get(`/empresas/${empresaId}/cabecalho`)).data;
      return { cabecalho };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

// CADASTRO

export const fetchCadastro = createAsyncThunk(
  'empresa/fetchCadastro',
  async ({ empresaId }, thunkAPI) => {
    try {
      const cadastro = (await axios.get(`/empresas/${empresaId}/cadastro`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/cadastro/verificar-alertas`)).data;
      return { cadastro, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

// UNIDADES

export const fetchUnidades = createAsyncThunk(
  'empresa/fetchUnidades',
  async ({ empresaId }, thunkAPI) => {
    try {
      const unidades = (await axios.get(`/empresas/${empresaId}/unidades`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/unidades/verificar-alertas`)).data;
      return { unidades, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchUnidade = createAsyncThunk(
  'empresa/fetchUnidade',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/unidades/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// CERTIFICADOS

export const fetchCertificados = createAsyncThunk(
  'empresa/fetchCertificados',
  async ({ empresaId }, thunkAPI) => {
    try {
      const certificados = (await axios.get(`/empresas/${empresaId}/certificados`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/certificados/verificar-alertas`)).data;
      return { certificados, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchCertificado = createAsyncThunk(
  'empresa/fetchCertificado',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/certificados/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// TRIBUTARIOS

export const fetchTributarios = createAsyncThunk(
  'empresa/fetchTributarios',
  async ({ empresaId }, thunkAPI) => {
    try {
      const tributarios = (await axios.get(`/empresas/${empresaId}/tributarios`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/tributarios/verificar-alertas`)).data;
      return { tributarios, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchTributario = createAsyncThunk(
  'empresa/fetchTributario',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/tributarios/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// SÓCIOS

export const fetchSocios = createAsyncThunk(
  'empresa/fetchSocios',
  async ({ empresaId }, thunkAPI) => {
    try {
      const socios = (await axios.get(`/empresas/${empresaId}/socios`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/socios/verificar-alertas`)).data;
      return { socios, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchSocio = createAsyncThunk(
  'empresa/fetchSocio',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/socios/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// CONTRATOS

export const fetchContratos = createAsyncThunk(
  'empresa/fetchContratos',
  async ({ empresaId }, thunkAPI) => {
    try {
      const contratos = (await axios.get(`/empresas/${empresaId}/contratos`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/contratos/verificar-alertas`)).data;
      return { contratos, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchContrato = createAsyncThunk(
  'empresa/fetchContrato',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/contratos/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// PLS

export const fetchPLs = createAsyncThunk(
  'empresa/fetchPLs',
  async ({ empresaId }, thunkAPI) => {
    try {
      const pls = (await axios.get(`/empresas/${empresaId}/pls`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/pls/verificar-alertas`)).data;
      return { pls, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchPL = createAsyncThunk(
  'empresa/fetchPL',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/pls/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// ADMINISTRADORES

export const fetchAdministradores = createAsyncThunk(
  'empresa/fetchAdministradores',
  async ({ empresaId }, thunkAPI) => {
    try {
      const administradores = (await axios.get(`/empresas/${empresaId}/administradores`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/administradores/verificar-alertas`)).data;
      return { administradores, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchAdministrador = createAsyncThunk(
  'empresa/fetchAdministrador',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/administradores/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// PROCURADORES

export const fetchProcuradores = createAsyncThunk(
  'empresa/fetchProcuradores',
  async ({ empresaId }, thunkAPI) => {
    try {
      const procuradores = (await axios.get(`/empresas/${empresaId}/procuradores`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/procuradores/verificar-alertas`)).data;
      return { procuradores, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchProcurador = createAsyncThunk(
  'empresa/fetchProcurador',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/procuradores/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// CONTAS

export const fetchContas = createAsyncThunk(
  'empresa/fetchContas',
  async ({ empresaId }, thunkAPI) => {
    try {
      const contas = (await axios.get(`/empresas/${empresaId}/contas`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/contas/verificar-alertas`)).data;
      return { contas, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchConta = createAsyncThunk(
  'empresa/fetchConta',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/contas/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// BENEFICIÁRIOS EMPRESA

export const fetchBeneficiariosEmpresa = createAsyncThunk(
  'empresa/fetchBeneficiariosEmpresa',
  async ({ empresaId }, thunkAPI) => {
    try {
      const beneficiariosEmpresa = (await axios.get(`/empresas/${empresaId}/beneficiarios-empresa`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/beneficiarios-empresa/verificar-alertas`)).data;
      return { beneficiariosEmpresa, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchBeneficiarioEmpresa = createAsyncThunk(
  'empresa/fetchBeneficiarioEmpresa',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/beneficiarios-empresa/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// REPRESENTANTES

export const fetchRepresentantes = createAsyncThunk(
  'empresa/fetchRepresentantes',
  async ({ empresaId }, thunkAPI) => {
    try {
      const representantes = (await axios.get(`/empresas/${empresaId}/representantes`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/representantes/verificar-alertas`)).data;
      return { representantes, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchRepresentante = createAsyncThunk(
  'empresa/fetchRepresentante',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/representantes/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// BENEFICIÁRIOS SÓCIO

export const fetchBeneficiariosSocio = createAsyncThunk(
  'empresa/fetchBeneficiariosSocio',
  async ({ empresaId }, thunkAPI) => {
    try {
      const beneficiariosSocio = (await axios.get(`/empresas/${empresaId}/beneficiarios-socio`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/beneficiarios-socio/verificar-alertas`)).data;
      return { beneficiariosSocio, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchBeneficiarioSocio = createAsyncThunk(
  'empresa/fetchBeneficiarioSocio',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/beneficiarios-socio/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// RDEIEDS

export const fetchRDEIEDs = createAsyncThunk(
  'empresa/fetchRDEIEDs',
  async ({ empresaId }, thunkAPI) => {
    try {
      const rdeieds = (await axios.get(`/empresas/${empresaId}/rde-ieds`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/rde-ieds/verificar-alertas`)).data;
      return { rdeieds, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchRDEIED = createAsyncThunk(
  'empresa/fetchRDEIED',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/rde-ieds/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// DEFs

export const fetchDEFs = createAsyncThunk(
  'empresa/fetchDEFs',
  async ({ empresaId }, thunkAPI) => {
    try {
      const defs = (await axios.get(`/empresas/${empresaId}/defs`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/defs/verificar-alertas`)).data;
      return { defs, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchDEF = createAsyncThunk(
  'empresa/fetchDEF',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/defs/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// CCEs

export const fetchCCEs = createAsyncThunk(
  'empresa/fetchCCEs',
  async ({ empresaId }, thunkAPI) => {
    try {
      const cces = (await axios.get(`/empresas/${empresaId}/cces`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/cces/verificar-alertas`)).data;
      return { cces, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchCCE = createAsyncThunk(
  'empresa/fetchCCE',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/cces/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// CBEs

export const fetchCBEs = createAsyncThunk(
  'empresa/fetchCBEs',
  async ({ empresaId }, thunkAPI) => {
    try {
      const cbes = (await axios.get(`/empresas/${empresaId}/cbes`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/cbes/verificar-alertas`)).data;
      return { cbes, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchCBE = createAsyncThunk(
  'empresa/fetchCBE',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/cbes/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// LICENÇAS

export const fetchLicencas = createAsyncThunk(
  'empresa/fetchLicencas',
  async ({ empresaId, search }, thunkAPI) => {
    try {
      if (typeof search !== 'string')
        search = thunkAPI.getState().empresa.licencasSearch;
      const licencas = (await axios.get(`/empresas/${empresaId}/licencas`, { params: { search } })).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/licencas/verificar-alertas`)).data;
      return { licencas, search, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchLicenca = createAsyncThunk(
  'empresa/fetchLicenca',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/licencas/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// DOCUMENTOS

export const fetchDocumentos = createAsyncThunk(
  'empresa/fetchDocumentos',
  async ({ empresaId, search }, thunkAPI) => {
    try {
      if (typeof search !== 'string')
        search = thunkAPI.getState().empresa.documentosSearch;
      const documentos = (await axios.get(`/empresas/${empresaId}/documentos`, { params: { search } })).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/documentos/verificar-alertas`)).data;
      return { documentos, search, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchDocumento = createAsyncThunk(
  'empresa/fetchDocumento',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/documentos/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// LIVROS DIGITAIS

export const fetchLivrosDigitais = createAsyncThunk(
  'empresa/fetchLivrosDigitais',
  async ({ empresaId }, thunkAPI) => {
    try {
      const livrosDigitais = (await axios.get(`/empresas/${empresaId}/livros-digitais`)).data;
      const alertaGrupo = (await axios.get(`/empresas/${empresaId}/livros-digitais/verificar-alertas`)).data;
      return { livrosDigitais, alertaGrupo };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  });

export const fetchLivroDigital = createAsyncThunk(
  'empresa/fetchLivroDigital',
  async ({ empresaId, id }, thunkAPI) => {
    try {
      if (id === 'create')
        return {};
      return (await axios.get(`/empresas/${empresaId}/livros-digitais/${id}`)).data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

// SLICE

export const slice = createSlice({
  name: 'empresa',
  initialState,
  reducers: {},
  extraReducers: {

    // CABECALHO

    [fetchCabecalho.fulfilled]: (state, action) => {
      state.cabecalho = action.payload.cabecalho;
      state.cabecalho.acessoId = state.cabecalho?.acesso?.id;
    },

    // CADASTRO

    [fetchCadastro.fulfilled]: (state, action) => {
      state.cadastro = action.payload.cadastro;
      state.cadastro.acessoId = action.payload.cadastro?.acesso?.id;
      state.cadastroAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchCadastro.rejected]: (state, action) => {
      state.cadastro = [];
      state.cadastroAlertaGrupo = unwrapAlertaGrupo('cadastro', action);
    },

    // UNIDADES

    [fetchUnidades.fulfilled]: (state, action) => {
      state.unidades = action.payload.unidades;
      state.unidadesAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchUnidades.rejected]: (state, action) => {
      state.unidades = [];
      state.unidadesAlertaGrupo = unwrapAlertaGrupo('unidades', action);
    },
    [fetchUnidade.fulfilled]: (state, action) => {
      state.unidade = action.payload;
      state.unidade.cnaePrimarioCodigo = action.payload.cnaePrimario?.codigo;
      state.unidade.cnaesSecundariosCodigos = action.payload.cnaesSecundarios?.map(it => it.codigo);
    },

    // CERTIFICADOS

    [fetchCertificados.fulfilled]: (state, action) => {
      state.certificados = action.payload.certificados;
      state.certificadosAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchCertificados.rejected]: (state, action) => {
      state.certificados = [];
      state.certificadosAlertaGrupo = unwrapAlertaGrupo('certificados', action);
    },
    [fetchCertificado.fulfilled]: (state, action) => {
      state.certificado = action.payload;
    },

    // TRIBUTARIOS

    [fetchTributarios.fulfilled]: (state, action) => {
      state.tributarios = action.payload.tributarios;
      state.tributariosAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchTributarios.rejected]: (state, action) => {
      state.tributarios = [];
      state.tributariosAlertaGrupo = unwrapAlertaGrupo('tributarios', action);
    },
    [fetchTributario.fulfilled]: (state, action) => {
      state.tributario = action.payload;
    },
    // SÓCIOS

    [fetchSocios.fulfilled]: (state, action) => {
      state.socios = action.payload.socios;
      state.sociosAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchSocios.rejected]: (state, action) => {
      state.socios = [];
      state.sociosAlertaGrupo = unwrapAlertaGrupo('socios', action);
    },
    [fetchSocio.fulfilled]: (state, action) => {
      state.socio = action.payload;
    },

    // CONTRATOS

    [fetchContratos.fulfilled]: (state, action) => {
      state.contratos = action.payload.contratos;
      state.contratosAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchContratos.rejected]: (state, action) => {
      state.contratos = [];
      state.contratosAlertaGrupo = unwrapAlertaGrupo('contratos', action);
    },
    [fetchContrato.fulfilled]: (state, action) => {
      state.contrato = action.payload;
      state.contrato.livroDigitalId = action.payload.livroDigital?.id;
    },

    // PLS

    [fetchPLs.fulfilled]: (state, action) => {
      state.pls = action.payload.pls;
      state.plsAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchPLs.rejected]: (state, action) => {
      state.pls = [];
      state.plsAlertaGrupo = unwrapAlertaGrupo('pls', action);
    },
    [fetchPL.fulfilled]: (state, action) => {
      state.pl = action.payload;
    },

    // ADMINISTRADORES

    [fetchAdministradores.fulfilled]: (state, action) => {
      state.administradores = action.payload.administradores;
      state.administradoresAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchAdministradores.rejected]: (state, action) => {
      state.administradores = [];
      state.administradoresAlertaGrupo = unwrapAlertaGrupo('administradores', action);
    },
    [fetchAdministrador.fulfilled]: (state, action) => {
      state.administrador = action.payload;
    },

    // PROCURADORES

    [fetchProcuradores.fulfilled]: (state, action) => {
      state.procuradores = action.payload.procuradores;
      state.procuradoresAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchProcuradores.rejected]: (state, action) => {
      state.procuradores = [];
      state.procuradoresAlertaGrupo = unwrapAlertaGrupo('procuradores', action);
    },
    [fetchProcurador.fulfilled]: (state, action) => {
      state.procurador = action.payload;
    },

    // CONTAS

    [fetchContas.fulfilled]: (state, action) => {
      state.contas = action.payload.contas;
      state.contasAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchContas.rejected]: (state, action) => {
      state.contas = [];
      state.contasAlertaGrupo = unwrapAlertaGrupo('contas', action);
    },
    [fetchConta.fulfilled]: (state, action) => {
      state.conta = action.payload;
    },

    // BENEFICIÁRIOS EMPRESA

    [fetchBeneficiariosEmpresa.fulfilled]: (state, action) => {
      state.beneficiariosEmpresa = action.payload.beneficiariosEmpresa;
      state.beneficiariosEmpresaAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchBeneficiariosEmpresa.rejected]: (state, action) => {
      state.beneficiariosEmpresa = [];
      state.beneficiariosEmpresaAlertaGrupo = unwrapAlertaGrupo('beneficiarios-empresa', action);
    },
    [fetchBeneficiarioEmpresa.fulfilled]: (state, action) => {
      state.beneficiarioEmpresa = action.payload;
    },

    // REPRESENTANTES

    [fetchRepresentantes.fulfilled]: (state, action) => {
      state.representantes = action.payload.representantes;
      state.representantesAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchRepresentantes.rejected]: (state, action) => {
      state.representantes = [];
      state.representantesAlertaGrupo = unwrapAlertaGrupo('representantes', action);
    },
    [fetchRepresentante.fulfilled]: (state, action) => {
      state.representante = action.payload;
      state.representante.socioId = action.payload.socio?.id;
    },

    // BENEFICIÁRIOS SÓCIO

    [fetchBeneficiariosSocio.fulfilled]: (state, action) => {
      state.beneficiariosSocio = action.payload.beneficiariosSocio;
      state.beneficiariosSocioAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchBeneficiariosSocio.rejected]: (state, action) => {
      state.beneficiariosSocio = [];
      state.beneficiariosSocioAlertaGrupo = unwrapAlertaGrupo('beneficiarios-socio', action);
    },
    [fetchBeneficiarioSocio.fulfilled]: (state, action) => {
      state.beneficiarioSocio = action.payload;
      state.beneficiarioSocio.socioId = action.payload.socio?.id;
    },

    // RDEIEDS

    [fetchRDEIEDs.fulfilled]: (state, action) => {
      state.rdeieds = action.payload.rdeieds;
      state.rdeiedsAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchRDEIEDs.rejected]: (state, action) => {
      state.rdeieds = [];
      state.rdeiedsAlertaGrupo = unwrapAlertaGrupo('rde-ieds', action);
    },
    [fetchRDEIED.fulfilled]: (state, action) => {
      state.rdeied = action.payload;
      state.rdeied.socioId = action.payload.socio?.id;
    },

    // DEFs

    [fetchDEFs.fulfilled]: (state, action) => {
      state.defs = action.payload.defs;
      state.defsAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchDEFs.rejected]: (state, action) => {
      state.defs = [];
      state.defsAlertaGrupo = unwrapAlertaGrupo('defs', action);
    },
    [fetchDEF.fulfilled]: (state, action) => {
      state.def = action.payload;
    },

    // CCEs

    [fetchCCEs.fulfilled]: (state, action) => {
      state.cces = action.payload.cces;
      state.ccesAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchCCEs.rejected]: (state, action) => {
      state.cces = [];
      state.ccesAlertaGrupo = unwrapAlertaGrupo('cces', action);
    },
    [fetchCCE.fulfilled]: (state, action) => {
      state.cce = action.payload;
    },

    // CBEs

    [fetchCBEs.fulfilled]: (state, action) => {
      state.cbes = action.payload.cbes;
      state.cbesAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchCBEs.rejected]: (state, action) => {
      state.cbes = [];
      state.cbesAlertaGrupo = unwrapAlertaGrupo('cbes', action);
    },
    [fetchCBE.fulfilled]: (state, action) => {
      state.cbe = action.payload;
    },

    // LICENÇAS

    [fetchLicencas.fulfilled]: (state, action) => {
      state.licencas = action.payload.licencas;
      state.licencasSearch = action.payload.search;
      state.licencasAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchLicencas.rejected]: (state, action) => {
      state.licencas = [];
      state.licencasSearch = '';
      state.licencasAlertaGrupo = unwrapAlertaGrupo('licencas', action);
    },
    [fetchLicenca.fulfilled]: (state, action) => {
      state.licenca = action.payload;
      state.licenca.unidadeId = action.payload.unidade?.id;
    },

    // DOCUMENTOS

    [fetchDocumentos.fulfilled]: (state, action) => {
      state.documentos = action.payload.documentos;
      state.documentosSearch = action.payload.search;
      state.documentosAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchDocumentos.rejected]: (state, action) => {
      state.documentos = [];
      state.documentosSearch = '';
      state.documentosAlertaGrupo = unwrapAlertaGrupo('documentos', action);
    },
    [fetchDocumento.fulfilled]: (state, action) => {
      state.documento = action.payload;
      state.documento.unidadeId = action.payload.unidade?.id;
    },

    // LIVROS DIGITAIS

    [fetchLivrosDigitais.fulfilled]: (state, action) => {
      state.livrosDigitais = action.payload.livrosDigitais;
      state.livrosDigitaisAlertaGrupo = action.payload.alertaGrupo;
    },
    [fetchLivrosDigitais.rejected]: (state, action) => {
      state.livrosDigitais = [];
      state.livrosDigitaisAlertaGrupo = unwrapAlertaGrupo('livros-digitais', action);
    },
    [fetchLivroDigital.fulfilled]: (state, action) => {
      state.livroDigital = action.payload;
      state.livroDigital.conteudos.forEach(conteudo => {
        conteudo.contratoId = conteudo.contrato?.id;
      });
    },
  },
});

export default slice.reducer;

// UTIL

function unwrapAlertaGrupo(nome, action) {
  return { nome, nivel: 2, alertas: [{ nivel: 2, mensagem: action.payload.mensagem }] };
}
