import { Col, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchLivroDigital, isEditableSelector, livroDigitalSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import { formatNumber } from "chart.js/helpers";
import styles from './_styles.less';
import ActionButtonEmpresaLivroDigitalGerarPdf from "../../../../components/action-button-empresa-livro-digital-gerar-pdf";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import useModulo from "../../../../hooks/useModulo";

export default function EmpresaLivroDigitalDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const livroDigital = useSelector(livroDigitalSelector);
  const { formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchLivroDigital.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const [contratoById, setContratoById] = useState({});
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const { moduloBasePath } = useModulo();

  const getAnexoByContratoId = contratoId => {
    if (!contratoId)
      return null;
    let contrato = contratoById[contratoId];
    if (!contrato) {
      setContratoById(contratoById => ({ ...contratoById, [contratoId]: {} }))
      unwrapAxiosPromise(axios.get(`/empresas/${empresaId}/contratos/${contratoId}`))
        .then(data => {
          setContratoById(contratoById => ({ ...contratoById, [contratoId]: data }))
        });
    }
    return contrato?.anexo;
  }

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/livros-digitais/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/livros-digitais`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/livros-digitais/${id}/`, `logs_/empresas/${empresaId}/livros-digitais/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        <ActionButtonEmpresaLivroDigitalGerarPdf empresaId={empresaId} livroDigital={livroDigital} noTitle/>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Natureza')}>{livroDigital?.natureza}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Número de Ordem')}>{formatNumber(livroDigital?.numeroOrdem)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Período Inicial')}>{formatDate(livroDigital?.periodoInicial)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Período Final')}>{formatDate(livroDigital?.periodoFinal)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Termo de Abertura')} value={livroDigital?.anexoTermoAbertura}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <label>
            {t('Conteúdo')}
            <table className={styles['conteudo']}>
              <tbody>
              {livroDigital?.conteudos?.map(conteudo => {
                const hasContratoId = typeof conteudo.contratoId === 'number';
                return (
                  <tr key={conteudo.id}>
                    <td className={styles['nome']}>
                      {!hasContratoId && <TextItem>{conteudo.nome}</TextItem>}
                      {hasContratoId && <TextItem>{conteudo.contrato?.nomeAto}</TextItem>}
                    </td>
                    <td className={styles['anexo']}>
                      {!hasContratoId && <TextAnexo value={conteudo.anexo}/>}
                      {hasContratoId && <TextAnexo value={getAnexoByContratoId(conteudo.contratoId)}/>}
                    </td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </label>
          {!(livroDigital?.conteudos?.length) && <>&nbsp;</>}
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Declaração de Responsabilidade')} value={livroDigital?.anexoDeclaracaoResponsabilidade}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Termo de Encerramento')} value={livroDigital?.anexoTermoEncerramento}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Termo de Autenticação')} value={livroDigital?.anexoTermoAutenticacao}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{livroDigital?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(livroDigital?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(livroDigital?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
      </Row>
    </Spin>
  );
}
