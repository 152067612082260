import { Col, Form, Input, InputNumber, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputCnpj from '../../../../components/input-cnpj';
import MiniForm from '../../../../components/mini-form';
import SelectEnderecoEstado from '../../../../components/select-endereco-estado';
import SelectEmpresaTributarioRegimeApuracao from "../../../../components/select-empresa-tributario-regime-apuracao";
import SelectEmpresaTributarioFormaTributacaoLucro from "../../../../components/select-empresa-tributario-forma-tributacao-lucro";
import SelectEmpresaTributarioIncidenciaTributaria from "../../../../components/select-empresa-tributario-incidencia-tributaria";
import SelectEmpresaTributarioCriterioApuracao from "../../../../components/select-empresa-tributario-criterio-apuracao";
import InputName from "../../../../components/input-name";
import InputCpf from "../../../../components/input-cpf";
import InputEmail from "../../../../components/input-email";
import TextItem from "../../../../components/text-item";

export default function EmpresaTributarioForm({ disabled, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="ano" label={t('Ano')}>
            <InputNumber disabled={disabled} min={1900} max={new Date().getFullYear()}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="regimeApuracao" label={t('Regime de Apuração')}>
            <SelectEmpresaTributarioRegimeApuracao disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="formaTributacaoLucro" label={t('Forma de Tributação do Lucro')}>
            <SelectEmpresaTributarioFormaTributacaoLucro disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="incidenciaTributaria" label={t('Incidência Tributária')}>
            <SelectEmpresaTributarioIncidenciaTributaria disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="criterioApuracao" label={t('Critério de Apuração')}>
            <SelectEmpresaTributarioCriterioApuracao disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <fieldset>
        <legend>{t('Contabilista')}</legend>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="contabNome" label={t('Nome')}>
              <InputName disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="contabCpf" label={t('CPF')}>
              <InputCpf disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="contabCrc" label={t('CRC')}>
              <Input disabled={disabled} maxLength={15}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="contabCnpj" label={t('CNPJ')}>
              <InputCnpj disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="contabFone1" label={t('Telefone 1')}>
              <Input disabled={disabled} maxLength={11}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="contabFone2" label={t('Telefone 2')}>
              <Input disabled={disabled} maxLength={11}/>
            </Form.Item>
          </Col>
          <Col flex="400px">
            <Form.Item name="contabEmail" label={t('E-mail')}>
              <InputEmail disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px">
            <Form.Item name="contabEstado" label={t('Estado')}>
              <SelectEnderecoEstado disabled={disabled}/>
            </Form.Item>
          </Col>
          <Col flex="200px">
            <Form.Item name="contabCidade" label={t('Cidade')}>
              <Input disabled={disabled} maxLength={50}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px">
            <Form.Item name="contabObs" label={t('Observação')}>
              <Input.TextArea autoSize disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </fieldset>
    </MiniForm>
  );
}

EmpresaTributarioForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
