import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextAnexo from '../../../../components/text-anexo';
import TextItem from '../../../../components/text-item';
import { EmpresaAdministrador, findByIdAndMap } from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { administradoreSelector, fetchAdministrador, isEditableSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";

const { Tipo, Remuneracao, Representacao } = EmpresaAdministrador;

export default function EmpresaAdministradorDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const administrador = useSelector(administradoreSelector);
  const { formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchAdministrador.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const { moduloBasePath } = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/administradores/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/administradores`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/administradores/${id}/`, `logs_/empresas/${empresaId}/administradores/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="300px"><TextItem label={t('Tipo')}>{findByIdAndMap(Tipo, administrador?.tipo, tipo => t(tipo.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Cargo')}>{administrador?.cargo}</TextItem></Col>
        <Col flex="300px"><TextItem label={t('Nome')}>{administrador?.nome}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="150px"><TextItem label={t('Data Inicial')}>{formatDate(administrador?.dataInicial)}</TextItem></Col>
        <Col flex="150px"><TextItem label={t('Prazo Indeterminado')}>{formatBoolean(administrador?.indeterminado)}</TextItem></Col>
        <Col flex="200px">
          {administrador?.indeterminado === false &&
            <TextItem label={t('Data Final')}>{formatDate(administrador?.dataFinal)}</TextItem>
          }
        </Col>
        <Col flex="100px"><TextItem label={t('e-CPF')}>{formatBoolean(administrador?.eCpf)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="300px"><TextItem label={t('Remuneração')}>{findByIdAndMap(Remuneracao, administrador?.remuneracao, remuneracao => t(remuneracao.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Representação')}>{findByIdAndMap(Representacao, administrador?.representacao, representacao => t(representacao.label))}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Poderes')}>{administrador?.poderes}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextAnexo label={t('Anexo')} value={administrador?.anexo}/></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{administrador?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(administrador?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(administrador?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{empresaId}</TextItem></Col>
      </Row>
    </Spin>
  );
}
