import { Form, Input, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../components/action-button';
import InputName from '../../../components/input-name';
import MiniFormDrawer from '../../../components/mini-form-drawer';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import InputColor from "../../../components/input-color";
import SelectConsultivoArea from "../../../components/select-consultivo-area";
import SelectConsultivoUsuario from "../../../components/select-consultivo-usuario";

const defaultValue = {
  id: null,
  nome: '',
  cor: '009EDB',
  areaPaiId: null,
  responsavelId: null,
  idExterno: null
};

export default function AreaDrawer({ onChange, onClose, visible }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);

  const fireChange = () => typeof onChange === 'function' ? onChange() : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  useEffect(() => {
    handleAfterClose();
  }, []);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#nome').focus();
  };

  const handleAfterClose = () => {
    form.resetFields();
    form.setFieldsValue(defaultValue);
  };

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.post('/consultivo/areas', values), form)
      .then(() => {
        message.success(t('Área de Atuação incluída com sucesso'));
        fireChange();
        fireClose();
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        onClick={() => form.submit()}
        loading={loadingSave}
      />
      <ActionButton.Close
        onClick={fireClose}
        disabled={loadingSave}
      />
    </>
  );

  return (
    <MiniFormDrawer
      drawerTitle={t('Criar Área de Atuação')}
      drawerFooter={footer}
      drawerWidth={440}
      drawerVisible={visible}
      form={form}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={handleAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="nome" label={t('Nome')}>
        <Input disabled={loadingSave} maxLength={100}/>
      </Form.Item>
      <Form.Item name="cor" label={t('Cor')}>
        <InputColor disabled={loadingSave}/>
      </Form.Item>
      <Form.Item name="areaPaiId" label={t('Área de Atuação Pai')}>
        <SelectConsultivoArea disabled={loadingSave} allowClear/>
      </Form.Item>
      <Form.Item name="responsavelId" label={t('Responsável')}>
        <SelectConsultivoUsuario disabled={loadingSave} allowClear/>
      </Form.Item>
      <Form.Item name="idExterno" label={t('Id Externo')}>
        <Input disabled={loadingSave} maxLength={100}/>
      </Form.Item>
    </MiniFormDrawer>
  );
}

AreaDrawer.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};
