import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { consultivoFiltroSelector } from "./consultivoFiltro";

const initialState = {
  filtro: {
    empresaId: null,
    unidadeId: null,
    status: null,
    dataAberturaDe: null,
    dataAberturaAte: null,
    dataPrevistaDe: null,
    dataPrevistaAte: null,
    idExterno: null,
    prioridade: null,
    tempoEstimadoDe: null,
    tempoEstimadoAte: null,
    dataInicioDe: null,
    dataInicioAte: null,
    dataConclusaoDe: null,
    dataConclusaoAte: null,
    titulo: null,
    sigilo: null,
    descricao: null,
    usuarioSolicitanteId: null,
    usuarioCriadorId: null,
    usuarioControleId: null,
    usuarioResponsavelId: null,
    emailsExtras: [],
    createdAtDe: null,
    createdAtAte: null,
    updatedAtDe: null,
    updatedAtAte: null,
    id: null,
    resposta: null,
    tags: [],
    permissoes: null,
    projetoId: null,
    consultaOrigemId: null,
  },

  painelEsquerdoColapsado: false,
  painelDireitoColapsado: true,

  consultas: [],

  preview: null
};

export const filtroSelector = state => state.consultivoPesquisa.filtro;
export const painelEsquerdoColapsadoSelector = state => state.consultivoPesquisa.painelEsquerdoColapsado;
export const painelDireitoColapsadoSelector = state => state.consultivoPesquisa.painelDireitoColapsado;
export const consultasSelector = state => state.consultivoPesquisa.consultas;
export const previewSelector = state => state.consultivoPesquisa.preview;

export const fetchConsultas = createAsyncThunk(
  'consultivoPesquisa/fetchConsultas',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const filtro = { ...consultivoFiltroSelector(state), ...filtroSelector(state) };
      const { data } = await axios.get('/consultivo/pesquisa/consultas', { params: filtro });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const previewConsulta = createAsyncThunk(
  'consultivoPesquisa/previewConsulta',
  async (payload, thunkAPI) => {
    try {
      const { data } = await axios.get(`/consultivo/pesquisa/consultas/${payload.id}`);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
)

export const slice = createSlice({
  name: 'consultivoPesquisa',
  initialState,
  reducers: {
    resetar: (state) => {
      return initialState;
    },
    colapsarPainelEsquerdo: (state, action) => {
      state.painelEsquerdoColapsado = action.payload;
    },
    colapsarPainelDireito: (state, action) => {
      state.painelDireitoColapsado = action.payload;
    },
    filtrar: (state, action) => {
      state.filtro = { ...state.filtro, ...action.payload };
    },
  },
  extraReducers: {
    [fetchConsultas.fulfilled]: (state, action) => {
      state.consultas = action.payload;
    },
    [previewConsulta.fulfilled]: (state, action) => {
      state.preview = action.payload;
      state.preview.empresaId = action.payload.empresa?.id;
      state.preview.unidadeId = action.payload.unidade?.id;
      state.preview.areaId = action.payload.area?.id;
      state.preview.tipoTrabalhoId = action.payload.tipoTrabalho?.id;
      state.preview.assuntoId = action.payload.assunto?.id;
      state.preview.usuarioSolicitanteId = action.payload.usuarioSolicitante?.id;
      state.preview.usuarioCriadorId = action.payload.usuarioCriador?.id;
      state.preview.usuarioControleId = action.payload.usuarioControle?.id;
      state.preview.usuarioResponsavelId = action.payload.usuarioResponsavel?.id;
      state.preview.projetoId = action.payload.projeto?.id;
      state.preview.consultaOrigemId = action.payload.consultaOrigem?.id;
    }
  },
});

export default slice.reducer;

export const { resetar, colapsarPainelEsquerdo, colapsarPainelDireito, filtrar } = slice.actions;
