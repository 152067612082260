// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W5i-kysJvxqxijOnt1IJQA\\=\\= {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n.dD3-nR4S494TgeXEJjZ9mQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://src/components/action-button-empresa-livro-digital-gerar-pdf/_styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;EACA,sBAAA;AAAF","sourcesContent":[".popover {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.checkbox-vertical {\n  display: flex;\n  flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "W5i-kysJvxqxijOnt1IJQA==",
	"checkbox-vertical": "dD3-nR4S494TgeXEJjZ9mQ=="
};
export default ___CSS_LOADER_EXPORT___;
