import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../../components/input-anexo';
import MiniForm from '../../../../../components/mini-form';

export default function ConsultivoConsultaAnexoForm({ disabled, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="nome" label={t('Nome')}>
            <Input disabled={disabled} maxLength={100}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexo" label={t('Anexo')}>
            <InputAnexo disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

ConsultivoConsultaAnexoForm.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
