import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaTributario } from '../../constants';

const { IncidenciaTributaria } = EmpresaTributario;

export default function SelectEmpresaTributarioIncidenciaTributaria(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(IncidenciaTributaria.NaoCumulativo)}
      {createOption(IncidenciaTributaria.Cumulativo)}
      {createOption(IncidenciaTributaria.Ambos)}
      {createOption(IncidenciaTributaria.NA)}
    </Select>
  );
}

SelectEmpresaTributarioIncidenciaTributaria.propTypes = {
  value: PropTypes.oneOf([
    IncidenciaTributaria.NaoCumulativo.id,
    IncidenciaTributaria.Cumulativo.id,
    IncidenciaTributaria.Ambos.id,
    IncidenciaTributaria.NA.id,
  ]),
  onChange: PropTypes.func,
};
