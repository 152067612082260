import { Button, Dropdown, Menu, Spin } from 'antd';
import axios from 'axios';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActionButton from '../../../components/action-button';
import FlexCard from '../../../components/flex-card';
import { Alerta, EmpresaTabs } from '../../../constants';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import { acessoSelector } from '../../../store/acessos';
import { funcoesSelector, isClienteGestorSelector } from '../../../store/autenticacao';
import { fetchAlertas, fetchAlertasByEmpresa, fetchEmpresas, filtradoSelector, filtroSelector } from '../../../store/empresas';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import { openPopupEmpresa } from '../../../util/window';
import styles from './_styles.less';

function getAlertaClassName(nivel) {
  switch (nivel) {
    default:
      return 'background-primary';
    case Alerta.Nivel.Aviso.id:
      return 'background-warning';
    case Alerta.Nivel.Erro.id:
      return 'background-error';
    case Alerta.Nivel.NA.id:
      return 'background-inactive';
  }
}

function EmpresaButtonIcon({ count, nivel }) {
  if (count > 0) {
    const className = [];
    className.push(getAlertaClassName(nivel));
    className.push(styles['button-icon']);
    return (
      <span>
          <div className={className.join(' ')}>
            {count}
          </div>
        </span>);
  }
  return null;
}

function EmpresaButton({ name, label, abas, disabled, onClick }) {
  const { t } = useTranslation();
  const count = abas?.alertas?.length;
  const isNA = abas?.nivel === Alerta.Nivel.NA.id;
  const className = [styles['button']];
  if (isNA)
    className.push('inactive');

  return (
    <Button
      block
      className={className.join(' ')}
      disabled={disabled}
      icon={isNA ? null : <EmpresaButtonIcon count={count} nivel={abas?.nivel}/>}
      onClick={e => {
        e.preventDefault();
        onClick(name);
      }}
    >
      {t(label)}
    </Button>);
}

export default function EmpresaCard({ empresa }) {
  const dispatch = useDispatchWithResult();
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const loading = useLoading(fetchEmpresas.typePrefix, fetchAlertasByEmpresa.typePrefix, fetchAlertas.typePrefix);
  const funcoes = useSelector(funcoesSelector);
  const acesso = useSelector(acessoSelector);
  const filtro = useSelector(filtroSelector);
  const filtrado = useSelector(filtradoSelector);
  const [loadingRemove, setLoadingRemove] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const ref = useRef(null);

  let className = '';
  if (empresa.ativa === false)
    className += 'inactive';

  useLayoutEffect(() => {
    function resize() {
      setWindowHeight(window.innerHeight);
    }

    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);

  const maxHeight = useMemo(() => {
    if (windowHeight && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const maxHeight = (windowHeight - rect.bottom);
      if (maxHeight >= 80 && maxHeight < 480)
        return (maxHeight - 24) + 'px';
    }
  }, [windowHeight, ref.current]);

  const overflowY = useMemo(() => maxHeight ? 'scroll' : undefined, [maxHeight]);

  const onUnload = () => {
    dispatch(fetchEmpresas({ acessoId: acesso?.id }));
  };

  const handleClick = tab => {
    openPopupEmpresa(empresa, tab, { onUnload });
  };

  const handleRemoveClick = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete('/empresas', { params: { ids: [empresa.id] } }))
      .then(() => dispatch(fetchEmpresas(acesso)))
      .finally(() => setLoadingRemove(false));
  };

  const buttons = [];
  if (isClienteGestor)
    buttons.push(
      <ActionButton.Remove
        key="remove"
        loading={loadingRemove}
        noTitle
        onClick={handleRemoveClick}
      />);

  let tabsFiltered = EmpresaTabs
    .filter(tab => tab.modulos.includes('regulatorio') === true)
    .filter(tab => funcoes?.includes(tab.name) && acesso?.funcoes?.includes(tab.name))
    .filter(tab => empresa.abas[tab.name]?.nivel !== Alerta.Nivel.NA.id);
  if (filtrado)
    tabsFiltered = tabsFiltered.filter(tab => {
      const aba = empresa.abas[tab.name];
      if (filtro.tabs.length > 0) {
        if (!filtro.tabs.includes(aba.nome)) {
          return false;
        }
      }
      if (filtro.sucessos || filtro.avisos || filtro.erros) {
        switch (aba.nivel) {
          default:
            if (!filtro.sucessos)
              return false;
            break;
          case Alerta.Nivel.NA.id:
            return false;
          case Alerta.Nivel.Aviso.id:
            if (!filtro.avisos)
              return false;
            break;
          case Alerta.Nivel.Erro.id:
            if (!filtro.erros)
              return false;
            break;
        }
      }
      return true;
    });

  return (
    <Dropdown
      key="filter-dropdown"
      overlay={(
        <Menu className={styles['empresa-menu']}>
          {tabsFiltered.map(tab =>
            <Menu.Item className={styles['empresa-menu-item']}>
              <EmpresaButton
                key={tab.name}
                abas={empresa.abas[tab.name]}
                disabled={loading}
                onClick={handleClick}
                {...tab}
              />
            </Menu.Item>)}
        </Menu>)}
      overlayStyle={{ maxHeight, overflowY }}
    >
      <Spin spinning={loading}>
        <FlexCard className={className}>
          <div ref={ref} className={styles['empresa-header']}>
            <div className={styles['empresa-title']}>
              {empresa.apelido || empresa.razaoSocial}
            </div>
            {buttons}
          </div>
        </FlexCard>
      </Spin>
    </Dropdown>
  );
}
