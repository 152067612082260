import { Col, Form, Input, message, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import InputDate from '../../../components/input-date';
import InputNumber from '../../../components/input-number';
import Logo from '../../../components/logo';
import MiniForm from '../../../components/mini-form';
import SelectAcesso from '../../../components/select-acesso';
import SelectBoolean from '../../../components/select-boolean';
import SelectEmpresaTipo from '../../../components/select-empresa-tipo';
import SelectMonth from '../../../components/select-month';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useLoading from '../../../hooks/useLoading';
import { cadastroSelector, fetchCadastro, fetchContratos, fetchDEFs, fetchPLs, fetchSocios, fetchTributarios } from '../../../store/empresa';
import unwrapAxiosPromise from '../../../util/unwrapAxiosPromise';
import useModulo from "../../../hooks/useModulo";

export default function EmpresaCadastroEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const { moduloAtual, moduloBasePath } = useModulo();

  useEffect(() => {
    document.querySelector('#ativa').focus();
  }, []);

  useEffect(() => {
    form.setFieldsValue(cadastro);
  }, [cadastro]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/empresas/${empresaId}/cadastro`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`${moduloBasePath}/empresas/${empresaId}/cadastro`);
        dispatch(fetchCadastro({ empresaId }));
        dispatch(fetchTributarios({ empresaId }));
        if (moduloAtual === 'societario') {
          dispatch(fetchSocios({ empresaId }));
          dispatch(fetchContratos({ empresaId }));
          dispatch(fetchPLs({ empresaId }));
          dispatch(fetchDEFs({ empresaId }));
        }
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/cadastro`);
  };

  return (
    <MiniForm form={form} onFinish={handleFinish} loading={loading}>
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="ativa" label={t('Ativa')}>
            <SelectBoolean disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="250px">
          <Form.Item name="acessoId" label={t('Acesso')}>
            <SelectAcesso disabled/>
          </Form.Item>
        </Col>
        <Col flex="250px">
          <Form.Item name="tipo" label={t('Tipo Corporativo')}>
            <SelectEmpresaTipo disabled/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Logo src={cadastro?.logo?.url} width={160} height={40}/>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="quotas" label={t('Número de Quotas/Ações')}>
            <InputNumber disabled={loadingSave} maximumFractionDigits={8}/>
          </Form.Item>
        </Col>
        <Col flex="250px">
          <Form.Item name="capitalSocialSubscrito" label={t('Capital Social Subscrito (R$)')}>
            <InputNumber disabled={loadingSave} maximumFractionDigits={2} minimumFractionDigits={2}/>
          </Form.Item>
        </Col>
        <Col flex="250px">
          <Form.Item name="capitalSocialIntegralizado" label={t('Capital Social Integralizado (R$)')}>
            <InputNumber disabled={loadingSave} maximumFractionDigits={2} minimumFractionDigits={2}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.capitalSocialSubscrito !== nextValues.capitalSocialSubscrito ||
              prevValues.capitalSocialIntegralizado !== nextValues.capitalSocialIntegralizado}
          >
            {({ getFieldValue, setFieldsValue }) => {
              const capitalSocialSubscrito = getFieldValue('capitalSocialSubscrito');
              const capitalSocialIntegralizado = getFieldValue('capitalSocialIntegralizado');
              if (capitalSocialSubscrito !== capitalSocialIntegralizado)
                return (
                  <Form.Item name="dataLimite" label={t('Data Limite p/ Integralização')}>
                    <InputDate disabled={loadingSave}/>
                  </Form.Item>);
              setFieldsValue({ 'dataLimite': null });
              return null;
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="mesEncerramento" label={t('Término do Exercício Social')}>
            <SelectMonth disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="150px">
          <Form.Item name="eppme" label={t('EPP ou ME')}>
            <SelectBoolean disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="100px"/>
        <Col flex="150px">
          <Form.Item name="tributario" label={t('Dados do Contador')}>
            <SelectBoolean disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="150px">
          <Form.Item name="def" label={t('Declarações Bacen')}>
            <SelectBoolean disabled={loadingSave}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={loadingSave}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="sisbacenLogin" label={t('Login Sisbacen')}>
            <Input disabled={loadingSave}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="sisbacenSenha" label={t('Senha Sisbacen')}>
            <Input disabled={loadingSave}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}
