import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaTributario } from '../../constants';

const { FormaTributacaoLucro } = EmpresaTributario;

export default function SelectEmpresaTributarioFormaTributacaoLucro(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(FormaTributacaoLucro.RealTrimestral)}
      {createOption(FormaTributacaoLucro.RealEstimativa)}
      {createOption(FormaTributacaoLucro.Presumido)}
      {createOption(FormaTributacaoLucro.Arbitrado)}
      {createOption(FormaTributacaoLucro.Imune)}
      {createOption(FormaTributacaoLucro.Isento)}
      {createOption(FormaTributacaoLucro.NaoContribuinte)}
      {createOption(FormaTributacaoLucro.NA)}
    </Select>
  );
}

SelectEmpresaTributarioFormaTributacaoLucro.propTypes = {
  value: PropTypes.oneOf([
    FormaTributacaoLucro.RealTrimestral.id,
    FormaTributacaoLucro.RealEstimativa.id,
    FormaTributacaoLucro.Presumido.id,
    FormaTributacaoLucro.Arbitrado.id,
    FormaTributacaoLucro.Imune.id,
    FormaTributacaoLucro.Isento.id,
    FormaTributacaoLucro.NaoContribuinte.id,
    FormaTributacaoLucro.NA.id,
  ]),
  onChange: PropTypes.func,
};
