import { Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { formatCnpj } from "../../util/cnpj";

export default function SelectConsultivoEmpresa({ acessoId, filter, onChange, value, ...props }) {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchSearch = useCallback(search => {
    if (acessoId > 0) {
      setLoadingSearch(true);
      axios.get(`/consultivo/empresas`, { params: { search, acessoId } })
        .then(({ data }) => {
          if (typeof filter === 'function')
            data = data.filter(filter);
          setOptions(data);
        })
        .catch(err => {
          setOptions([]);
        })
        .finally(() => setLoadingSearch(false));
    } else
      setOptions([]);
  }, [acessoId]);

  const handleChange = id => {
    onChange(id);
  };

  const handleSearch = useDebouncedCallback(500, search => {
    fetchSearch(search);
  }, []);

  useEffect(() => {
    fetchSearch();
  }, [fetchSearch]);

  return (
    <Select
      filterOption={false}
      loading={loadingSearch}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch
      value={value}
      {...props}
    >
      {options.map(option => (
        <Select.Option key={option.id} value={option.id}>
          {formatCnpj(option.cnpj)} - {option.nome}
        </Select.Option>
      ))}
    </Select>
  );
}

SelectConsultivoEmpresa.propTypes = {
  filter: PropTypes.func,
  onChange: PropTypes.func,
  params: PropTypes.any,
  value: PropTypes.number,
};
