import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import { EmpresaProcurador, findByIdAndMap } from '../../../constants';
import useFormatter from '../../../hooks/useFormatter';
import { compareNumbers } from '../../../util/number';
import { compareStrings } from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";

const { Tipo, Representacao } = EmpresaProcurador;

export default function EmpresaProcuradoresTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Nomes'),
      dataIndex: 'nomes',
      key: 'nomes',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)} className="ws-pre-line text-align-left">
          {record.nomes.join('\n') || t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Tipo'),
      dataIndex: 'tipo',
      key: 'tipo',
      width: 100,
      sorter: (a, b) => compareNumbers(a.tipo, b.tipo),
      render: (text, record) => findByIdAndMap(Tipo, record.tipo, tipo => t(tipo.label)),
    },
    {
      title: t('Representação'),
      dataIndex: 'representacao',
      key: 'representacao',
      width: 100,
      sorter: (a, b) => compareNumbers(a.representacao, b.representacao),
      render: (text, record) => findByIdAndMap(Representacao, record.representacao, representacao => t(representacao.label)),
    },
    {
      title: t('Data Inicial'),
      dataIndex: 'dataInicial',
      key: 'dataInicial',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataInicial, b.dataInicial),
      render: (text, record) => formatDate(record.dataInicial),
    },
    {
      title: t('Data Final'),
      dataIndex: 'dataFinal',
      key: 'dataFinal',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataFinal, b.dataFinal),
      render: (text, record) => record.indeterminado ? t('Indeterminado') : formatDate(record.dataFinal),
    },
    {
      title: t('Poderes'),
      dataIndex: 'poderes',
      key: 'poderes',
      sorter: (a, b) => compareStrings(a.poderes, b.poderes),
      render: (text) => <TableCellEllipsis text={text}/>
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

EmpresaProcuradoresTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
