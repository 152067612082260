import { Col, Form, Input, InputNumber, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAnexo from '../../../../components/input-anexo';
import InputDate from '../../../../components/input-date';
import MiniForm from '../../../../components/mini-form';
import InputName from "../../../../components/input-name";
import InputEmpresaLivroDigitalConteudos from "../../../../components/input-empresa-livro-digital-conteudos";

export default function EmpresaLivroDigitalForm({ disabled, empresaId, form, loading, onFinish, title }) {
  const { t } = useTranslation();

  return (
    <MiniForm form={form} loading={loading} onFinish={onFinish}>
      {title}
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="natureza" label={t('Natureza')}>
            <InputName disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="numeroOrdem" label={t('Número de Ordem')}>
            <InputNumber disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px">
          <Form.Item name="periodoInicial" label={t('Período Inicial')}>
            <InputDate disabled={disabled}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="periodoFinal" label={t('Período Final')}>
            <InputDate disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexoTermoAbertura" label={t('Termo de Abertura')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="conteudos" label={t('Conteúdo')}>
            <InputEmpresaLivroDigitalConteudos empresaId={empresaId} name="conteudos" disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexoDeclaracaoResponsabilidade" label={t('Declaração de Responsabilidade')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexoTermoEncerramento" label={t('Termo de Encerramento')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="anexoTermoAutenticacao" label={t('Termo de Autenticação')}>
            <InputAnexo disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="obs" label={t('Observação')}>
            <Input.TextArea autoSize disabled={disabled}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>);
}

EmpresaLivroDigitalForm.propTypes = {
  disabled: PropTypes.bool,
  empresaId: PropTypes.number,
  form: PropTypes.object,
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
  title: PropTypes.node,
};
