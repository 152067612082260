import { Form, Input, Select } from "antd";
import SelectConsultivoArea from "../../../components/select-consultivo-area";
import SelectConsultivoAssunto from "../../../components/select-consultivo-assunto";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import SelectConsultivoTipoTrabalho from "../../../components/select-consultivo-tipo-trabalho";
import SelectConsultivoUsuario from "../../../components/select-consultivo-usuario";
import { consultivoFiltroAplicar, consultivoFiltroResetar, consultivoFiltroSelector } from "../../../store/consultivoFiltro";
import { useSelector } from "react-redux";
import SelectAcesso from "../../../components/select-acesso";
import PesquisaFormItem from "./_PesquisaFormItem";
import styles from './_styles.less';
import { fetchConsultas, filtrar, filtroSelector, resetar } from "../../../store/consultivoPesquisa";
import ActionButton from "../../../components/action-button";
import ActionBar from "../../../components/action-bar";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import useLoading from "../../../hooks/useLoading";
import SelectConsultivoEmpresa from "../../../components/select-consultivo-empresa";
import SelectConsultivoUnidade from "../../../components/select-consultivo-unidade";
import SelectConsultivoConsultaStatus from "../../../components/select-consultivo-consulta-status";
import InputDate from "../../../components/input-date";
import SelectConsultivoConsultaPrioridade from "../../../components/select-consultivo-consulta-prioridade";
import InputNumber from "../../../components/input-number";
import SelectBoolean from "../../../components/select-boolean";
import InputEmails from "../../../components/input-emails";
import ActionLink from "../../../components/action-link";

export default function PesquisaPanel() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const filtro = { ...useSelector(consultivoFiltroSelector), ...useSelector(filtroSelector) };
  const loading = useLoading(fetchConsultas.typePrefix);
  const [exibirFiltrosAdicionais, setExibirFiltrosAdicionais] = useState(false);

  useEffect(() => {
    form.setFieldsValue(filtro);
  }, [filtro])

  const handleSubmit = useCallback(values => {
    const { acessoId, areaId, tipoTrabalhoId, assuntoId, usuarioId, ...filtro } = values;
    dispatch(consultivoFiltroAplicar({ acessoId, areaId, tipoTrabalhoId, assuntoId, usuarioId }));
    dispatch(filtrar(filtro));
    dispatch(fetchConsultas());
  }, []);

  const handleReset = useCallback(() => {
    dispatch(consultivoFiltroResetar());
    dispatch(resetar());
  }, []);

  const handleMaisFitlrosClick = useCallback(() => {
    setExibirFiltrosAdicionais(true);
  }, []);

  const handleMenosFitlrosClick = useCallback(() => {
    setExibirFiltrosAdicionais(false);
  }, []);

  return (
    <Form
      form={form}
      className={styles.PesquisaPanel}
      noValidate
      initialValues={filtro}
      onFinish={handleSubmit}
      onReset={handleReset}
    >
      <div className={styles.cabecalho}>
        <ActionBar>
          <ActionButton primary icon={<SearchOutlined/>} loading={loading} htmlType="submit">
            {t('Pesquisar')}
          </ActionButton>
          <ActionButton icon={<ClearOutlined/>} disabled={loading} htmlType="reset">
            {t('Limpar')}
          </ActionButton>
        </ActionBar>
      </div>
      <div className={styles.conteudo}>
        <PesquisaFormItem label={t('Título')} name="titulo">
          <Input allowClear/>
        </PesquisaFormItem>
        <PesquisaFormItem label={t('Descrição')} name="descricao">
          <Input allowClear/>
        </PesquisaFormItem>
        <PesquisaFormItem label={t('Resposta')} name="resposta">
          <Input allowClear/>
        </PesquisaFormItem>
        <PesquisaFormItem label={t('Tags')} name="tags">
          <Select allowClear mode="tags" dropdownStyle={{ display: 'none' }}/>
        </PesquisaFormItem>
        <PesquisaFormItem label={t('Área de Atuação')} name="areaId">
          <SelectConsultivoArea allowClear/>
        </PesquisaFormItem>
        <PesquisaFormItem
          label={t('Tipo de Trabalho')}
          name="tipoTrabalhoId"
          shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
        >
          {({ getFieldValue }) =>
            <SelectConsultivoTipoTrabalho areaId={getFieldValue('areaId')} allowClear/>
          }
        </PesquisaFormItem>
        <PesquisaFormItem label={t('Acesso')} name="acessoId">
          <SelectAcesso allowClear/>
        </PesquisaFormItem>
        {exibirFiltrosAdicionais && (
          <>
            <PesquisaFormItem
              label={t('Assunto')}
              name="assuntoId"
              shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
            >
              {({ getFieldValue }) =>
                <SelectConsultivoAssunto areaId={getFieldValue('areaId')} allowClear/>
              }
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Usuário')} name="usuarioId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem
              label={t('Empresa')}
              name="empresaId"
              shouldUpdate={(prevValues, nextValues) => prevValues.acessoId !== nextValues.acessoId}
            >
              {({ getFieldValue }) =>
                <SelectConsultivoEmpresa allowClear acessoId={getFieldValue('acessoId')}/>
              }
            </PesquisaFormItem>
            <PesquisaFormItem
              label={t('Unidade')}
              name="unidadeId"
              shouldUpdate={(prevValues, nextValues) => prevValues.empresaId !== nextValues.empresaId}
            >
              {({ getFieldValue }) =>
                <SelectConsultivoUnidade allowClear acessoId={getFieldValue('acessoId')} empresaId={getFieldValue('empresaId')}/>
              }
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Status')} name="status">
              <SelectConsultivoConsultaStatus allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Abertura de')} name="dataAberturaDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Abertura até')} name="dataAberturaAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Data Prevista de')} name="dataPrevistaDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Data Prevista até')} name="dataPrevistaAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Id Externo')} name="idExterno">
              <Input allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Prioridade')} name="prioridade">
              <SelectConsultivoConsultaPrioridade allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Tempo Estimado de')} name="tempoEstimadoDe">
              <InputNumber allowClear maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')}/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Tempo Estimado até')} name="tempoEstimadoAte">
              <InputNumber allowClear maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')}/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Início de')} name="dataInicioDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Início até')} name="dataInicioAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Concluído de')} name="dataConclusaoDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Concluído até')} name="dataConclusaoAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Sigilo')} name="sigilo">
              <SelectBoolean allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Solicitante')} name="usuarioSolicitanteId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Criador')} name="usuarioCriadorId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Controle')} name="usuarioControleId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Responsável')} name="usuarioResponsavelId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('E-mails Extras')} name="emailsExtras">
              <InputEmails allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Criado de')} name="createdAtDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Criado até')} name="createdAtAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Alterado de')} name="updatedAtDe">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Alterado até')} name="updatedAtAte">
              <InputDate allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Id')} name="id">
              <InputNumber allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Permissões')} name="usuarioPermissaoId">
              <SelectConsultivoUsuario allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Projeto')} name="projetoId">
              <InputNumber allowClear/>
            </PesquisaFormItem>
            <PesquisaFormItem label={t('Consulta Origem')} name="consultaOrigemId">
              <InputNumber allowClear/>
            </PesquisaFormItem>
          </>
        )}
      </div>
      <div className={styles.rodape}>
        {!exibirFiltrosAdicionais &&
          <ActionLink onClick={handleMaisFitlrosClick}>
            {t('Mais filtros')}
          </ActionLink>
        }
        {exibirFiltrosAdicionais &&
          <ActionLink onClick={handleMenosFitlrosClick}>
            {t('Menos filtros')}
          </ActionLink>
        }
      </div>
    </Form>
  )
}
