import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../components/action-bar';
import ActionButton from '../../../../components/action-button';
import TextItem from '../../../../components/text-item';
import { EmpresaTributario, Endereco, findByIdAndMap } from '../../../../constants';
import useFormatter from '../../../../hooks/useFormatter';
import useLoading from '../../../../hooks/useLoading';
import { fetchTributario, isEditableSelector, tributarioSelector } from '../../../../store/empresa';
import { openPopup } from '../../../../util/window';
import useModulo from "../../../../hooks/useModulo";
import { formatCnpj } from "../../../../util/cnpj";
import { formatCpf } from "../../../../util/cpf";

const { RegimeApuracao, FormaTributacaoLucro, IncidenciaTributaria, CriterioApuracao, } = EmpresaTributario;

export default function EmpresaTributarioDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const tributario = useSelector(tributarioSelector);
  const { formatDate, formatDateTime, formatBoolean } = useFormatter();
  const loading = useLoading(fetchTributario.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const { moduloBasePath } = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/tributarios/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/tributarios`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/tributarios/${id}/`, `logs_/empresas/${empresaId}/tributarios/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Ano')}>{tributario?.ano}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label={t('Regime de Apuração')}>{findByIdAndMap(RegimeApuracao, tributario?.regimeApuracao, it => t(it.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Forma de Tributação do Lucro')}>{findByIdAndMap(FormaTributacaoLucro, tributario?.formaTributacaoLucro, it => t(it.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Incidência Tributária')}>{findByIdAndMap(IncidenciaTributaria, tributario?.incidenciaTributaria, it => t(it.label))}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Critério de Apuração')}>{findByIdAndMap(CriterioApuracao, tributario?.criterioApuracao, it => t(it.label))}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{tributario?.obs}</TextItem></Col>
      </Row>

      <fieldset>
        <legend>{t('Contabilista')}</legend>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Nome')}>{tributario?.contabNome}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('CPF')}>{formatCpf(tributario?.contabCpf)}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('CRC')}>{tributario?.contabCrc}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('CNPJ')}>{formatCnpj(tributario?.contabCnpj)}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Telefone 1')}>{tributario?.contabFone1}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Telefone 2')}>{formatCpf(tributario?.contabFone2)}</TextItem></Col>
          <Col flex="400px"><TextItem label={t('E-mail')}>{tributario?.contabEmail}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label={t('Estado')}>{findByIdAndMap(Endereco.Estado, tributario?.contabEstado, it => t(it.label))}</TextItem></Col>
          <Col flex="200px"><TextItem label={t('Cidade')}>{tributario?.contabCidade}</TextItem></Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col flex="800px"><TextItem label={t('Observação')}>{tributario?.contabObs}</TextItem></Col>
        </Row>
      </fieldset>

      <fieldset>
        <legend/>
        <Row gutter={[16, 8]}>
          <Col flex="200px"><TextItem label="Criado em">{formatDateTime(tributario?.createdAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(tributario?.updatedAt)}</TextItem></Col>
          <Col flex="200px"><TextItem label="Id">{tributario?.id}</TextItem></Col>
        </Row>
      </fieldset>
    </Spin>
  );
}
