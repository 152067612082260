import {Card} from 'antd';
import React from 'react';
import {Route, Switch} from 'react-router';
import EmpresaTributariosDetailPage from './_detail';
import EmpresaTributarioPage from './tributario';

export default function EmpresaTributariosPage() {
  return (
      <Card bordered={false}>
        <Switch>
          <Route path="/*/empresas/:empresaId/tributarios/:id" component={EmpresaTributarioPage} />
          <Route path="/*/empresas/:empresaId" component={EmpresaTributariosDetailPage} />
        </Switch>
      </Card>);
}
