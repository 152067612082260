import React from 'react';
import { Route, Switch } from 'react-router';
import { Router as RouterDom } from 'react-router-dom';
import IndexPage from '../pages';
import { Error404Page } from '../pages/404';
import AutenticarPage from '../pages/autenticar';
import SocietarioDashboardPage from '../pages/societario/dashboard';
import SocietarioDetalhadoPage from '../pages/societario/detalhado';
import SocietarioTabelaPage from '../pages/societario/tabela';
import RegulatorioDashboardPage from '../pages/regulatorio/dashboard';
import RegulatorioTabelaPage from '../pages/regulatorio/tabela';
import RegulatorioDetalhadoPage from '../pages/regulatorio/detalhado';
import ComplianceDashboardPage from '../pages/compliance/dashboard';
import ComplianceTabelaPage from '../pages/compliance/tabela';
import ComplianceDetalhadoPage from '../pages/compliance/detalhado';
import DrivePage from '../pages/drive';
import DriveIdPage from '../pages/drive-id';
import EmailsPage from '../pages/emails';
import EmpresaPage from '../pages/empresa';
import LogsPage from '../pages/logs';
import RecuperarAcessoPage from '../pages/recuperar-acesso';
import RedefinirSenhaPage from '../pages/redefinir-senha';
import SplashPage from '../pages/splash';
import UsuariosPage from '../pages/usuarios';
import OrganogramaPage from "../pages/societario/organograma";
import OrganogramaPopupPage from "../pages/societario/organograma-popup";
import WorkflowsPage from "../pages/workflows";
import WorkflowPage from "../pages/workflows/id";
import AmbientesPage from "../pages/ambientes";
import HomePage from "../pages/home";
import ConsultivoDashboardPage from "../pages/consultivo/dashboard";
import ConsultivoAreasPage from "../pages/consultivo/areas";
import ConsultivoAreaPage from "../pages/consultivo/area";
import ConsultivoConsultaPage from "../pages/consultivo/consulta";
import ConsultivoProjetoPage from "../pages/consultivo/projeto";
import ConsultivoQuadroPage from "../pages/consultivo/quadro";
import ConsultivoPage from "../pages/consultivo";
import ConsultivoPesquisaPage from "../pages/consultivo/pesquisa";

export default function Router({ history }) {
  return (
    <RouterDom history={history}>
      <Switch>
        <Route path="/" exact component={IndexPage}/>
        <Route path="/home" exact component={HomePage}/>
        <Route path="/ambientes" exact component={AmbientesPage}/>
        <Route path="/autenticar" exact component={AutenticarPage}/>
        <Route path="/splash" exact component={SplashPage}/>
        <Route path="/societario/dashboard" exact component={SocietarioDashboardPage}/>
        <Route path="/societario/tabela" exact component={SocietarioTabelaPage}/>
        <Route path="/societario/detalhado" exact component={SocietarioDetalhadoPage}/>
        <Route path="/societario/organograma" component={OrganogramaPage}/>
        <Route path="/societario/organograma-popup" component={OrganogramaPopupPage}/>
        <Route path="/regulatorio/dashboard" exact component={RegulatorioDashboardPage}/>
        <Route path="/regulatorio/tabela" exact component={RegulatorioTabelaPage}/>
        <Route path="/regulatorio/detalhado" exact component={RegulatorioDetalhadoPage}/>
        <Route path="/compliance/dashboard" exact component={ComplianceDashboardPage}/>
        <Route path="/compliance/tabela" exact component={ComplianceTabelaPage}/>
        <Route path="/compliance/detalhado" exact component={ComplianceDetalhadoPage}/>
        <Route path="/consultivo" exact component={ConsultivoPage}/>
        <Route path="/consultivo/dashboard" exact component={ConsultivoDashboardPage}/>
        <Route path="/consultivo/quadro" exact component={ConsultivoQuadroPage}/>
        <Route path="/consultivo/pesquisa" exact component={ConsultivoPesquisaPage}/>
        <Route path="/consultivo/areas" exact component={ConsultivoAreasPage}/>
        <Route path="/consultivo/areas/:id/:tab?" component={ConsultivoAreaPage}/>
        <Route path="/consultivo/consultas/:id/:tab?" component={ConsultivoConsultaPage}/>
        <Route path="/consultivo/projetos/:id/:tab?" component={ConsultivoProjetoPage}/>
        <Route path="/*/empresas/:id/:tab?" component={EmpresaPage}/>
        <Route path="/drive" component={DrivePage}/>
        <Route path="/drive-id" component={DriveIdPage}/>
        <Route path="/emails" component={EmailsPage}/>
        <Route path="/logs" component={LogsPage}/>
        <Route path="/recuperar-acesso" exact component={RecuperarAcessoPage}/>
        <Route path="/redefinir-senha" exact component={RedefinirSenhaPage}/>
        <Route path="/usuarios" component={UsuariosPage}/>
        <Route path="/workflows/:id" component={WorkflowPage}/>
        <Route path="/workflows" component={WorkflowsPage}/>
        <Route path="*" component={Error404Page}/>
      </Switch>
    </RouterDom>
  );
}
