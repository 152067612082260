import { Menu, Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import ActionLink from '../../../components/action-link';
import useLoading from '../../../hooks/useLoading';
import { cabecalhoSelector, fetchCabecalho, isEditableSelector } from '../../../store/empresa';
import { formatCnpj } from '../../../util/cnpj';
import { openPopup } from '../../../util/window';
import styles from '../_styles.less';
import EmpresaDrawer from './_EmpresaDrawer';
import useModulo from "../../../hooks/useModulo";

export default function EmpresaCabecalho() {
  const isEditable = useSelector(isEditableSelector);
  const cabecalho = useSelector(cabecalhoSelector);
  const loading = useLoading(fetchCabecalho.typePrefix);
  const match = useRouteMatch();
  const id = match.params.id;
  const { moduloAtual } = useModulo();
  const [empresaDrawerVisible, setEmpresaDrawerVisible] = useState(false);

  const handleOrganograma = () => {
    openPopup(`/societario/organograma-popup?empresaId=${id}`, `organograma_empresaId_/${id}`);
  }

  const handleDriveClick = () => {
    openPopup(`/drive?empresaId=${id}`, `drive_empresaId_${id}`);
  };

  const handleEditClick = () => {
    setEmpresaDrawerVisible(true);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${id}/cabecalho/`, `logs_/empresas/${id}/cabecalho/`);
  };

  const handleEmpresaDrawerClose = () => {
    setEmpresaDrawerVisible(false);
  };

  const buttons = [];
  if (moduloAtual === 'societario')
    buttons.push(
      <Menu.Item key="organograma">
        <ActionLink.Organograma noTitle onClick={handleOrganograma}/>
      </Menu.Item>,
    );
  buttons.push(
    <Menu.Item key="drive">
      <ActionLink.Drive noTitle onClick={handleDriveClick}/>
    </Menu.Item>,
  );
  if (isEditable) {
    buttons.push(
      <Menu.Item key="edit">
        <ActionLink.Edit noTitle onClick={handleEditClick}/>
      </Menu.Item>,
    );
  }
  buttons.push(
    <Menu.Item key="logs">
      <ActionLink.Logs noTitle onClick={handleLogs}/>
    </Menu.Item>,
  );

  if (loading) {
    return (
      <Menu className={styles['empresa-menu']} theme="dark" mode="horizontal" selectable={false}>
        <Menu.Item>
          <Spin/>
        </Menu.Item>
      </Menu>);
  }

  return (
    <>
      <Menu className={styles['empresa-menu']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
        <Menu.Item>
          {cabecalho?.apelido || cabecalho?.razaoSocial} ({formatCnpj(cabecalho?.cnpj)})
        </Menu.Item>
      </Menu>
      <Menu className={styles['empresa-menu-buttons']} theme="dark" mode="horizontal" selectable={false} disabledOverflow>
        {buttons}
      </Menu>
      <EmpresaDrawer visible={empresaDrawerVisible} onClose={handleEmpresaDrawerClose}/>
    </>
  );
}
