import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaAdministrador } from '../../constants';

const { Remuneracao } = EmpresaAdministrador;

export default function SelectEmpresaAdministradorRemuneracao(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(Remuneracao.ProLabore)}
      {createOption(Remuneracao.PJ)}
      {createOption(Remuneracao.CLT)}
      {createOption(Remuneracao.Outros)}
    </Select>
  );
}

SelectEmpresaAdministradorRemuneracao.propTypes = {
  value: PropTypes.oneOf([
    Remuneracao.ProLabore.id,
    Remuneracao.PJ.id,
    Remuneracao.CLT.id,
    Remuneracao.Outros.id,
  ]),
  onChange: PropTypes.func,
};
