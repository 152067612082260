import React, { useRef, useState } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import { useSelector } from "react-redux";
import { isGestorSelector } from "../../../store/autenticacao";
import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import unwrapAxiosPromise from "../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../components/action-bar";
import ActionButton from "../../../components/action-button";
import MiniTable from "../../../components/mini-table";
import FlexCard from "../../../components/flex-card";
import AreasTable from "./_AreasTable";
import AreaDrawer from "./_AreaDrawer";
import { openPopupConsultivoArea } from "../../../util/window";

export default function ConsultivoAreasPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [activeSearch, setActiveSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [areaDrawerVisible, setAreaDrawerVisible] = useState(false);

  const onUnload = () => {
    handleSearch(activeSearch);
  };

  const handleSearch = search => {
    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get('/consultivo/areas', { params: { search } }))
      .then(data => {
        setActiveSearch(search);
        setDataSource(data);
      })
      .finally(() => setLoadingSearch(false));
  };

  const handleCreateClick = () => {
    setAreaDrawerVisible(true);
  };

  const handleRemove = () => {
    setLoadingRemove(true);
    unwrapAxiosPromise(axios.delete('/consultivo/areas', { params: { ids: selectedIds } }))
      .then(() => {
        setSelectedIds([]);
        handleSearch(activeSearch);
      })
      .finally(() => setLoadingRemove(false));
  };

  const handleClick = area => {
    openPopupConsultivoArea(area, 'cadastro', { onUnload });
  };

  const handleAreaChange = () => {
    handleSearch(activeSearch);
  };

  const handleAreaDrawerClose = () => {
    setAreaDrawerVisible(false);
  };

  const title = () => {
    return (
      <ActionBar onSearch={handleSearch}>
        <ActionButton.Create onClick={handleCreateClick}/>
        <ActionButton.Remove
          disabled={selectedIds.length === 0}
          onClick={handleRemove}
          loading={loadingRemove}
        />
        <MiniTable.DownloadButton disabled={loadingSearch || loadingRemove || dataSource.length === 0} miniTableRef={miniTableRef}/>
      </ActionBar>);
  };

  return (
    <AuthenticatedLayout modulo="consultivo">
      <Breadcrumb>
        <Breadcrumb.Item>{t('Áreas de Atuação')}</Breadcrumb.Item>
      </Breadcrumb>
      <FlexCard>
        <AreasTable
          dataSource={dataSource}
          loading={loadingSearch || loadingRemove}
          miniTableRef={miniTableRef}
          onClick={handleClick}
          onSelectedIdsChange={setSelectedIds}
          selectedIds={selectedIds}
          title={title}
        />
      </FlexCard>
      <AreaDrawer
        onChange={handleAreaChange}
        onClose={handleAreaDrawerClose}
        visible={areaDrawerVisible}
      />
    </AuthenticatedLayout>);
}
